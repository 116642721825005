import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
    ADD_PENDING_PICK,
    REMOVE_PENDING_PICK
} from '../../actions/actions'

import '../../css/pickbuttoncontainer.css'

class PickButtonContainer extends Component {   
    isTeamSelected(team) {
        return this.props.pendingPick.filter(p => p.teamSelected === team).length > 0 ? "team_selected" : ""
    }
  
    pickButtonClicked(teamSelected, opposingTeam) {
        // if team is in pendingPick, remove it from pending pick
        if (this.props.pendingPick.filter(p => p.teamSelected === teamSelected).length > 0)
            this.props.removePendingPick(teamSelected)
        else { // else, add it to pendingPick
            if (this.props.pendingPick.filter(p => p.teamSelected === opposingTeam).length > 0) // should not be able to pick both teams in a matchup
                this.props.removePendingPick(opposingTeam)
            this.props.addPendingPick({ teamSelected: teamSelected, gameTimeUTC: `${this.props.gameTimeUTC}Z` }) // add Z for UTC time
        }
    }

    render() {
        return (
            <div className="pickButtons_container" style={{ display: this.props.pickButtonVisibility }}>
                <button className={"btn_accent " + this.isTeamSelected(this.props.awayTeam)} disabled={this.props.awayDisabled} onClick={() => this.pickButtonClicked(this.props.awayTeam, this.props.homeTeam)}>Pick {this.props.awayTeam}</button><br />
                <button className={"btn_accent " + this.isTeamSelected(this.props.homeTeam)} disabled={this.props.homeDisabled} onClick={() => this.pickButtonClicked(this.props.homeTeam, this.props.awayTeam)}>Pick {this.props.homeTeam}</button>
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        pickButtonVisibility: state.pickButtonVisibility,
        pendingPick: state.pendingPick
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addPendingPick: (pick) => dispatch({ type: ADD_PENDING_PICK, pick }),
        removePendingPick: (pick) => dispatch({ type: REMOVE_PENDING_PICK, pick })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PickButtonContainer)
import React, { Component } from 'react'
import { connect } from 'react-redux'

import axios from 'axios'

import { SET_POOL_ID } from '../../actions/actions'
import { Themes, updateUserEmail, SET_SUSER_ID } from '../../actions/settings'
import { sanitize } from '../../utils'

import '../../css/index.css'
import '../../css/header.css'
import '../../css/joinpool.css'
import Dashboard from './Dashboard'

const InviteCode = {
    NOT_SUBMIT: "NOT_SUBMIT",
    SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
    SUBMIT_FAIL: "SUBMIT_FAIL"
}

class JoinPoolForm extends Component {
    constructor (props) {
        super(props)
        this.state = { 
            codeSubmitted: InviteCode.NOT_SUBMIT,
            updateEmailError: "",
            joinComplete: false
        }
    }

    // TODO: if duplicate ID is generated, need to repeat this
    componentDidMount() {
        axios.get("/api/generate_new_suserid")
            .then((response) => {
                console.log(response)
                this.props.setUserId(response.data.sUserId)
            })
            .catch(() => { console.log('Generate sUserId - Internal server error') })
    }
    
    submitInviteCode = event => {
        event.preventDefault()
        const cleanUserInput = sanitize(event.target.elements.invite_code.value)
        axios.get("/api/join_pool", { params: { inviteCode: cleanUserInput }})
        .then((response) => {
            this.props.setPoolId(response.data[0].poolId)
            axios({
                method: "POST",
                url: "/api/create_new_user",
                data: {
                    theme: Themes.LIGHT,
                    poolId: response.data[0].poolId,
                    sUserId: this.props.sUserId,
                }
            })
            .then(() => { 
                console.log('New user has been sent to server') 
                this.setState({ codeSubmitted: InviteCode.SUBMIT_SUCCESS })
            })
            .catch(() => { console.log('New user: Internal server error') })
        })
        .catch(() => {
            console.log('User data load - Internal server error') 
            this.setState({ codeSubmitted: InviteCode.SUBMIT_FAIL })
        })
    }

    renderInviteForm() {
        return <>
            <p>Enter your pool's invite code to join your pool. This will be provided by your pool manager.</p>
            <form onSubmit={this.submitInviteCode}>
                <label><input className="input_field" placeholder="Enter Invite Code" id="invite_code"/></label>
                <input className="btn_form_accent" type="submit" value="Submit"/>
            </form>
        </>
    }

    formUpdateEmail = event => {
        event.preventDefault()
        const email = sanitize(event.target.elements.new_email.value)
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        if (emailPattern.test(email)) {
            this.props.updateUserEmail(email)
            this.setState({ updateEmailError: "" })
            document.getElementById("update_email_form").reset()
        } else
            this.setState({ updateEmailError: "Please enter a valid email address." })
    }

    takeUserToPool() {
        this.setState({ joinComplete: true }) // this will render a Dashboard component
    }

    renderInviteProcessFlow() {
        switch (this.state.codeSubmitted) {
            case InviteCode.NOT_SUBMIT:
                return this.renderInviteForm()       
            case InviteCode.SUBMIT_FAIL:  
                return <>
                    <p className="error">The invite code you entered was not recognized. Please contact your pool's manager to ensure you have the correct invite code.</p>
                    {this.renderInviteForm()}
                </>
            case InviteCode.SUBMIT_SUCCESS:
                return <>
                    <img className="submit-status-icon" alt="green check" src="/images/greencheck.png" width="25px" height="25px"/>
                    <h4>You have successfully joined your pool!</h4><br/>
                    <div id="update_email">
                        <h4>Optional: update email address</h4>
                        <p>All Survivin' Pool Manager emails will go to this email address: <strong>{this.props.userEmail}</strong></p>
                        <p>Would you like these emails to go to a different address?</p>
                        <p className="error">{this.state.updateEmailError}</p>
                        <form id="update_email_form" onSubmit={this.formUpdateEmail}> 
                            <label><input className="input_field" placeholder="New Email Address" id="new_email"/></label>
                            <input className="btn_form_plain" type="submit" value="Update"/>
                        </form>
                        <ul id="update_email_notes">
                            <li>If you update where your email notifications are sent, you will still use your Gmail address to sign in.</li>
                            <li>You can update this later on if you wish.</li>
                        </ul>
                    </div><br/>
                    <button className="btn_form_accent" id="btn_take_pool" onClick={() => this.takeUserToPool()}>Take Me To My Pool</button>
                </>
            default:
                return <></>            
        }
    }
    
    render() {
        if (this.state.joinComplete)
            return <Dashboard/>
        return (
            <div id="join_pool" className="lightTheme">
                <div id="header">
                    <h1 className="header_item" id="header_title">Survivin' <span className="header_subheading">Pool Manager</span></h1>
                </div>
                <div id="join_pool_body">
                    <div id="join_pool_content">
                        <h2>Join your pool</h2>
                        <p>Welcome, {this.props.userName.substr(0, this.props.userName.indexOf(' '))}!</p><br/>
                        {this.renderInviteProcessFlow()}
                    </div>
                </div>
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        sUserId: state.sUserId,
        userName: state.userName,
        userEmail: state.userEmail,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateUserEmail: (response) => dispatch(updateUserEmail( response )),
        setPoolId: (response) => dispatch({ type: SET_POOL_ID, response }),
        setUserId: (id) => dispatch({ type: SET_SUSER_ID, id }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JoinPoolForm)
import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../css/poolview.css'

import { PlayerStatuses, BuybackChoices } from '../../actions/actions'

class FinancialDetails extends Component {
    // max pot: this is what the pot would be if everyone who still can uses their optional buyback

    // TODO: Venmo string should be supplied from DB, not hardcoded
    render() {
        const currentEntryFees = this.props.entryFee * this.props.poolEntries.length
        const currentBuyback = (this.props.buybackAllowed ? (this.props.buybackFee * this.props.poolEntries.filter(e => e.buybackChoice === BuybackChoices.YES).length) : 0)
        const maxPossibleBuyback = (this.props.buybackAllowed ? (this.props.buybackFee * this.props.poolEntries.filter(e => e.buybackChoice !== BuybackChoices.NO).length) : 0)
        const currentPaidPot = (this.props.poolEntries.filter(e => e.paidEntry === true).length * this.props.entryFee) + (this.props.buybackAllowed ? (this.props.poolEntries.filter(e => e.paidBuyback === true).length * this.props.buybackFee) : 0)
        return <div className="pool_view_container" id="financial_container">
            <div className="financial_amount_container"><p className="financial_title">Total Pot</p><span className="financial_dollar_amount">${currentEntryFees + currentBuyback}</span></div>
            <div className="financial_amount_container"><p className="financial_title">Current Paid</p><span className="financial_dollar_amount">${currentPaidPot}</span></div>
            {this.props.buybackAllowed && <div className="financial_amount_container"><p className="financial_title">Max Pot</p><span className="financial_dollar_amount">${currentEntryFees + maxPossibleBuyback}</span></div>}
            {/*<div className="financial_amount_container"><p className="financial_title">Entry Fee</p><span className="financial_dollar_amount">${this.props.entryFee}</span></div>*/}
            {/*{this.props.buybackAllowed && <div className="financial_amount_container"><p className="financial_title">Buyback Fee</p><span className="financial_dollar_amount">${this.props.buybackFee}</span></div>}*/}
            <div className="financial_amount_container"><p className="financial_title">Total Alive</p><span className="financial_dollar_amount">{this.props.poolEntries.filter(e => e.status !== PlayerStatuses.ELIMINATED.toUpperCase()).length}</span></div>
            <div className="financial_amount_container"><p className="financial_title">Total Perfect</p><span className="financial_dollar_amount">{this.props.poolEntries.filter(e => e.status === PlayerStatuses.ALIVE.toUpperCase()).length}</span></div>
            <div className="financial_amount_container"><p className="financial_title">Total Elim.</p><span className="financial_dollar_amount">{this.props.poolEntries.filter(e => e.status === PlayerStatuses.ELIMINATED.toUpperCase()).length}</span></div>
            <div className="financial_amount_container"><p className="financial_title">Total Entries</p><span className="financial_dollar_amount">{this.props.poolEntries.length}</span></div>
        </div>
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        entryFee: state.entryFee,
        buybackAllowed: state.buybackAllowed,
        buybackFee: state.buybackFee,
        poolEntries: state.poolEntries,
    }
}

export default connect(mapStateToProps, null)(FinancialDetails)
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Results } from '../../actions/actions'
import { isMobile } from 'react-device-detect'
import { filterCurrentEntryPicks } from '../../utils'

import '../../css/pickssidebar.css'

class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            hideSidebar: false
        }
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        if (isMobile) {
            this.setState({ hideSidebar: true })
            document.addEventListener('mousedown', this.handleClickOutside)
        }
    }
    
    componentWillUnmount() {
        if (isMobile) { document.removeEventListener('mousedown', this.handleClickOutside) }
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }
    
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target))
            this.setState({ hideSidebar: true })
    }
    
    // render a check mark, X mark, or nothing depending on result
    renderResult(result, rowSpan) {
        switch (result) {
            case Results.WIN:
                return <td className="pick_win" rowSpan={rowSpan}>{isMobile ? <img className="mobile_result_icon" alt="check" src="/images/icon_greencheck_og.png" width="15px" height="15px"/> : String.fromCharCode(0x2714)}</td> // check mark
            case Results.LOSS:
                return <td className="pick_loss" rowSpan={rowSpan}>{isMobile ? <img className="mobile_result_icon" alt="x" src="/images/icon_redx_og.png" width="15px" height="15px"/> : String.fromCharCode(0x2716)}</td> // "X" mark 
            default:
                return <td rowSpan={rowSpan}></td> // pending
        }
    }

    renderPicksTable() {
        let thisEntryPicks = filterCurrentEntryPicks(this.props.picks, this.props.currentEntry) // filter picks to just current entry's picks

        return thisEntryPicks.map(pick => pick.teams.map((t, index) => 
            <tr key={`${pick.userEntry}-${pick.week}-${index}`}>
                {index === 0 && <td className="pick_week" rowSpan={pick.teams.length}>{(pick.season.includes("POST") ? "P" : "") + pick.week}</td>}
                <td className="pick_logo"><img alt="logo" src={`/team_logos/teamlogo_${t}.png`} width="30px" height="30px"/></td>
                <td>{t}</td>
                {pick.teams.length > 1 ? this.renderResult(pick.results[index], "1") : <td></td>}
                {index === 0 && this.renderResult(pick.aggregatedResult, pick.teams.length)}
            </tr>
        ))
    }

    toggleSidebar() {
        this.setState({ hideSidebar: !this.state.hideSidebar })
    }

    render() {
        if (this.state.hideSidebar)
            return <div className="sidebar_flyout" id="sidebar_flyout_open" onClick={() => this.toggleSidebar()}>{String.fromCharCode(0x203A)}</div>
        else
            return (
                <div ref={this.setWrapperRef} id="sidebar">
                    {isMobile && <div className="sidebar_flyout" id="sidebar_flyout_close" onClick={() => this.toggleSidebar()}>{String.fromCharCode(0x2039)}</div>}
                    <table id="picks_table"> 
                    <caption>Picks: {this.props.entries && this.props.entries.length > 0 ? this.props.entries.filter(e => e.userEntry === this.props.currentEntry)[0].entryName : ""}</caption><tbody>
                    <tr><th>Wk</th><th colSpan="2">Team</th><th></th><th></th></tr>
                    {this.renderPicksTable()}
                    </tbody></table>
                    {(!this.props.picks || this.props.picks.length === 0) && <><br/><br/><span id="zero_picks">Once you make your first pick, it will appear here.</span></> }
                </div>
            )
    }
}  

// ------- Redux -------
function mapStateToProps(state) {
    return {
        picks: state.picks,
        entries: state.entries,
        currentEntry: state.currentEntry,
    }
}

export default connect(mapStateToProps)(Sidebar)
import React, { Component } from 'react'
import Dashboard from './components/General/Dashboard'
import JoinPoolForm from './components/General/JoinPoolForm'
import { connect } from 'react-redux'

import axios from 'axios'

import { stadiumImages } from './constants/stadiumImages'
import { SET_CURRENT_WEEK, SET_POOL_ID, SET_TEAM_RESULTS } from './actions/actions'
import {
    SET_THEME,
    SET_SUSER_ID,
    SET_USER_NAME,
    SET_USER_EMAIL,
    SET_USER_IMG,
    SET_CURRENT_SEASON,
    SET_YEAR,
    SET_IS_OFFSEASON,
    SET_NUM_REGULAR_SEASON_WEEKS,
    SET_NUM_POSTSEASON_WEEKS,
    SET_TIMEZONE_OFFSET,
    SET_DO_LOGOUT,
} from './actions/settings'

import './css/index.css'
import './css/loginscreen.css'

class App extends Component {
    constructor (props) {
        super(props)
        this.state = { 
            isReady: false,
            loggedIn: false,
            joinPool: false,
            redirectUri: null
        }
    }

    componentDidMount() { 
        // get the redirect URI for auth
        const promiseRedirect = axios.get("/auth/google/url")
        .then((response) => {
            this.setState({ redirectUri: response.data }) 
        })
        .catch(() => { console.log("Auth error") })

        Promise.all([promiseRedirect]).then(() => {
            this.setState({ isReady: true })
        })

        axios.get("/auth/me", { withCredentials: true })
        .then((response) => {
            this.props.setUserName(response.data.name)
            this.props.setUserEmail(response.data.email)
            this.props.setUserImg(response.data.picture)
            const promiseApiData = this.loadApiData()

            Promise.all([promiseApiData]).then(() => {
                axios.get("/api/users")
                .then((response) => {
                    if (response.data.length > 0) { // if user exists in DB
                        this.loadUserData(response.data[0])
                        this.setState({ loggedIn: true })
                    } else { // user does not exist in DB
                        this.setState({ loggedIn: true, joinPool: true })
                    }
                    this.props.setDoLogout(false) // reset
                })
                .catch(() => { console.log('User data load - Internal server error') })
            })
        })
        .catch(() => { console.log('User not authenticated') })
    }

    calculateTimeZoneOffset() {
        return (new Date().getTimezoneOffset() / 60)
    }

    loadUserData(user) {
        this.props.setUserId(user.sUserId)
        this.props.setTheme(user.theme) // set user's theme
        this.props.setPoolId(user.poolId) // set user's poolId
        this.props.setTimeZoneOffset(this.calculateTimeZoneOffset()) // set user's current timezone
    }

    loadApiData() {
        return (
            axios.get("/api/api_data")
            .then((response) => {
                this.props.setCurrentWeek(response.data[0].currentWeek)
                this.props.setCurrentSeason(response.data[0].currentSeason)
                this.props.setYear(response.data[0].currentSeason.substring(0,4))
                this.props.setIsOffseason(response.data[0].isOffseason)
                this.props.setNumRegularSeasonWeeks(response.data[0].numRegularSeasonWeeks)
                this.props.setNumPostseasonWeeks(response.data[0].numPostseasonWeeks)
                this.props.setTeamResults(response.data[0].teamResults)
            })
            .catch(() => { console.log('Api data load - Internal server error') })
        )
    }
    
    render() {
        if (!this.state.isReady) { // show loading spinner while data loads
            return (
                <div id="loading" className="lightTheme">
                    <img id="loading_spinner" src="/loading_light.gif" alt="loading"/>
                </div>
            )
        } else {
            if (!this.state.loggedIn || this.props.doLogout) {
                const i = Math.floor(Math.random() * stadiumImages.length)
                const gradStyle = {
                    background: `linear-gradient(327deg, rgba(0,0,0,1) 0%, rgba(30,30,30,0.35) 50%, rgba(0,0,0,1) 100%), url(${stadiumImages[i].img}) no-repeat`,
                    backgroundSize: "cover"
                }

                return (
                    <div id="login_screen" style={gradStyle}>
                        <div id="login_title">
                            <h1>Survivin' <span>Pool Manager</span></h1>
                            <p>Your all-in-one survivor pool manager</p>
                        </div>
                        <br/>
                        <div id="btn_google_login"><a href={this.state.redirectUri}>Login with Google</a></div>
                        <div id="footer_links">
                            <a href='privacyPolicy.html'>Privacy Policy</a>
                        </div>
                        <div id="stadium_caption">
                            <p><strong>{stadiumImages[i].name}</strong></p>
                            <p>{stadiumImages[i].location}</p>
                        </div>
                    </div>            
                )
            } else {
                return (this.state.joinPool ? <JoinPoolForm/> : <Dashboard/> ) // new users directed to JoinPoolForm
            }
        }
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        doLogout: state.doLogout,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setDoLogout: (response) => dispatch({ type: SET_DO_LOGOUT, response }),
        setUserId: (id) => dispatch({ type: SET_SUSER_ID, id }),
        setUserName: (response) => dispatch({ type: SET_USER_NAME, response }),
        setUserEmail: (response) => dispatch({ type: SET_USER_EMAIL, response }),
        setUserImg: (response) => dispatch({ type: SET_USER_IMG, response }),
        setTheme: (theme) => dispatch({ type: SET_THEME, theme }),
        setPoolId: (response) => dispatch({ type: SET_POOL_ID, response }),
        setCurrentWeek: (week) => dispatch({ type: SET_CURRENT_WEEK, week }),
        setCurrentSeason: (response) => dispatch({ type: SET_CURRENT_SEASON, response }),
        setYear: (response) => dispatch({ type: SET_YEAR, response }),
        setIsOffseason: (response) => dispatch({ type: SET_IS_OFFSEASON, response }),
        setNumRegularSeasonWeeks: (response) => dispatch({ type: SET_NUM_REGULAR_SEASON_WEEKS, response }),
        setNumPostseasonWeeks: (response) => dispatch({ type: SET_NUM_POSTSEASON_WEEKS, response }),
        setTeamResults: (response) => dispatch({ type: SET_TEAM_RESULTS, response }),
        setTimeZoneOffset: (response) => dispatch({ type: SET_TIMEZONE_OFFSET, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
import axios from 'axios'

// action types
export const SET_DO_LOGOUT = 'SET_DO_LOGOUT'
export const SET_SUSER_ID = 'SET_SUSER_ID'
export const SET_CURRENT_SEASON = 'SET_CURRENT_SEASON'
export const SET_YEAR = 'SET_YEAR'
export const TOGGLE_THEME = 'TOGGLE_THEME'
export const SET_THEME = 'SET_THEME'
export const SET_PAGE = 'SET_PAGE'
export const TOGGLE_RECOS = 'TOGGLE_RECOS'
export const SET_TEAMS_PER_WEEK = 'SET_TEAMS_PER_WEEK'
export const ADD_SUBMIT_DEADLINE = 'ADD_SUBMIT_DEADLINE'
export const UPDATE_SUBMIT_DEADLINE = 'UPDATE_SUBMIT_DEADLINE'
export const SET_USER_NAME = 'SET_USER_NAME'
export const SET_USER_EMAIL = 'SET_USER_EMAIL'
export const SET_USER_IMG = 'SET_USER_IMG'
export const SET_SHOW_BUYBACK_MODAL = 'SET_SHOW_BUYBACK_MODAL'
export const SET_IS_OFFSEASON = 'SET_IS_OFFSEASON'
export const SET_RESUBMIT_ALLOWED = 'SET_RESUBMIT_ALLOWED'
export const SET_MAX_ENTRIES_PER_USER = 'SET_MAX_ENTRIES_PER_USER'
export const SET_NUM_REGULAR_SEASON_WEEKS = 'SET_NUM_REGULAR_SEASON_WEEKS'
export const SET_NUM_POSTSEASON_WEEKS = 'SET_NUM_POSTSEASON_WEEKS'
export const SET_SHOW_HAM_MENU = 'SET_SHOW_HAM_MENU'
export const SET_SHOW_MANUAL_PICK_MODAL = 'SET_SHOW_MANUAL_PICK_MODAL'
export const SET_SHOW_EMAIL_REPORT_MODAL = 'SET_SHOW_EMAIL_REPORT_MODAL'
export const SET_SHOW_GENERIC_EMAIL_MODAL = 'SET_SHOW_GENERIC_EMAIL_MODAL'
export const SET_PAYMENT_STRING = 'SET_PAYMENT_STRING'
export const SET_TIMEZONE_OFFSET = 'SET_TIMEZONE_OFFSET'
export const SET_SHOW_AVATAR_MENU = 'SET_SHOW_AVATAR_MENU'

// other constants
export const Themes = {
    DARK: 'DARK',
    LIGHT: 'LIGHT'
}

// TODO: add additional pages to this constant
export const Pages = {
    //-HOME: 'HOME',
    MY_PICKS: 'MY PICKS',
    MY_POOL: 'MY POOL',
    INFO: 'POOL INFO',
    ABOUT: 'ABOUT'
    //-RESOURCES: 'RESOURCES' 
}

// action creators
export function setDoLogout(response) {
    return { type: SET_DO_LOGOUT, response }
}

export function setUserId(id) {
    return { type: SET_SUSER_ID, id }
}

export function setCurrentSeason(response) {
    return { type: SET_CURRENT_SEASON, response }
}

export function setYear(response) {
    return { type: SET_YEAR, response }
}

export function setIsOffseason(response) {
    return { type: SET_IS_OFFSEASON, response }
}

export function toggleTheme() {
    return { type: TOGGLE_THEME }
}

export function setTheme(theme) {
    return { type: SET_THEME, theme }
}

export function setPage(response) {
    return { type: SET_PAGE, response }
}

export const updateUserThemeSetting = () => {
    return (dispatch, getState) => {
        let newTheme = (getState().theme === Themes.DARK ? Themes.LIGHT : Themes.DARK)
        dispatch(setTheme(newTheme))
        axios({
            method: "PUT",
            url: "/api/update_theme",
            data: { theme: newTheme }
        })
        .then(() => {console.log('Theme update has been sent to server')})
        .catch(() => {console.log('Theme update: Internal server error')})
    }
}

export function toggleRecos() {
    return { type: TOGGLE_RECOS }
}

export function setTeamsPerWeek(response) {
    return { type: SET_TEAMS_PER_WEEK, response }
}

export function addSubmitDeadline(response) {
    return { type: ADD_SUBMIT_DEADLINE, response }
}

export function updateSubmitDeadline(response) {
    return { type: UPDATE_SUBMIT_DEADLINE, response }
}

export function setUserName(response) {
    return { type: SET_USER_NAME, response }
}

export function setUserEmail(response) {
    return { type: SET_USER_EMAIL, response }
}

export function setUserImg(response) {
    return { type: SET_USER_IMG, response }
}

export const updateUserEmail = (email) => {
    return dispatch => {
        return axios({
            method: "PUT",
            url: "/api/update_user_email",
            data: { email: email }
        })
        .then((response) => {
            console.log('User email update has been sent to server')
            dispatch(setUserEmail(email))
            return response.data
        })
        .catch((response) => {
            console.log('User email update: Internal server error')
            return response.data
        })
    }
}

export function setShowBuybackModal(response) {
    return { type: SET_SHOW_BUYBACK_MODAL, response }
}

export function setResubmitAllowed(response) {
    return { type: SET_RESUBMIT_ALLOWED, response }
}

export function setMaxEntriesPerUser(response) {
    return { type: SET_MAX_ENTRIES_PER_USER, response }
}

export function setNumRegularSeasonWeeks(response) {
    return { type: SET_NUM_REGULAR_SEASON_WEEKS, response }
}

export function setNumPostseasonWeeks(response) {
    return { type: SET_NUM_POSTSEASON_WEEKS, response }
}

export function setShowHamMenu(response) {
    return { type: SET_SHOW_HAM_MENU, response }
}

export function setShowManualPickModal(response) {
    return { type: SET_SHOW_MANUAL_PICK_MODAL, response }
}

export function setShowEmailReportModal(response) {
    return { type: SET_SHOW_EMAIL_REPORT_MODAL, response }
}

export function setShowGenericEmailModal(response) {
    return { type: SET_SHOW_GENERIC_EMAIL_MODAL, response }
}

export function setPaymentString(response) {
    return { type: SET_PAYMENT_STRING, response }
}

export function setTimezoneOffset(response) {
    return { type: SET_TIMEZONE_OFFSET, response }
}

export function setShowAvatarMenu(response) {
    return { type: SET_SHOW_AVATAR_MENU, response }
}

import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../css/aboutpage.css'

class AboutPage extends Component {
    render() {
        return ( 
            <div id="about_container">
                <div id="about_content">
                    <h2 className="about_heading">About Survivin' Pool Manager</h2>
                    <br/>
                    <h3 className="about_heading">Privacy Policy</h3>
                    <p>To view the Survivin' Pool Manager privacy policy, <a href='privacyPolicy.html'>click here.</a></p>
                    <br/>
                    <h3 className="about_heading">How the Survivin' Web App Works</h3>
                    <p>Survivin' is desgined to streamline and modernize the survivor pool experience by making it easy to track your picks and submit them each week. It enforces the rules of the pool automatically (such as not allowing you to submit a repeat team, for example) so that managing the pool is easier for everyone.</p>
                    <p>If you suffer your first loss, the next time you log in a box will appear asking if you would like to buy back in. You can make a selection (yes/no), or opt to decide at a later time. If you choose to decide later, then the next time you go to make a pick you will be required at that time to confirm that you would like to buy back in before you can submit the pick.</p>
                    <br/>
                    <h3 className="about_heading">Nerdier Details</h3>
                    <p>The Survivin' web app is written using ReactJS. It contains over 5000 lines of code and I've spent literally hundreds of hours working on it throughout the past year and a half. Working on a project that I'm passionate about has helped me expand my skillset as a software developer, and things I've learned from working on this have translated to my day job and vice-versa.</p>
                    <p>Survivin' leverages multiple 3rd-party APIs to pull in NFL game data each week, and I store pool/pick/entry data in a NoSQL database. The primary API that I use offers both free and paid tiers, and since the budget for this project is $0.00, the data I have to work with is limited. For example, I am not able to provide actual score info for games, as these values are scrambled (inaccurate on purpose) in the free tier.  Fortunately, the standings data is available for me to use, so I determine the outcome of each game by comparing the current standings to what the standings were at the beginning of the week to see which teams won or lost.</p>                    
                    <p>The game data and pick outcomes are updated automatically at 1:00am EST after night games, and also on Sundays at 5:00pm EST and 9:00pm EST. The new week begins at 3:00am EST on Tuesdays.</p>
                    <br/>
                    
                    <br/>
                    
                </div>
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        entryFee: state.entryFee,
        buybackFee: state.buybackFee,
        maxEntriesPerUser: state.maxEntriesPerUser,
        numRegularSeasonWeeks: state.numRegularSeasonWeeks,
        paymentString: state.paymentString
    }
}

export default connect(mapStateToProps, null)(AboutPage)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Themes, SET_SHOW_GENERIC_EMAIL_MODAL } from '../../actions/settings'

import axios from 'axios'

import { sanitize } from '../../utils'
import '../../css/emailreportmodal.css'

const Headings = {
    DEFAULT: "Send Generic Email",
    EMAIL_SENT: "Email Sent",
    ERROR: "Error",
    EMPTY: "Error"
}

const Messages = {
    DEFAULT: "Send an email message to all members of the pool.",
    EMAIL_SENT: "The email has been successfully sent to all members of the pool.",
    ERROR: "The email could not be sent. Please try again later.",
    EMPTY: "Cannot send an empty message."
}

const MainButtonText = {
    SEND_EMAIL: "Send Email",
    CLOSE: "Close"
}

class GenericEmailModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            heading: Headings.DEFAULT,
            message: Messages.DEFAULT,
            mainButtonText: MainButtonText.SEND_EMAIL,
        }
    }

    mainButtonClicked = event => {
        event.preventDefault()
        if (this.state.mainButtonText === MainButtonText.SEND_EMAIL) {
            this.setState({ isSubmitting: true })
            this.sendEmail(sanitize(event.target.elements.report_message.value))
        } else if (this.state.mainButtonText === MainButtonText.CLOSE) {
            this.resetModal()
            this.props.setShowGenericEmailModal(false)
        }
    }

    resetModal() {
        this.setState({
            isSubmitting: false,
            heading: Headings.DEFAULT,
            message: Messages.DEFAULT,
            mainButtonText: MainButtonText.SEND_EMAIL
        })
    }

    sendEmail(message) {
        if (message.trim().length === 0) {
            this.setState({
                heading: Headings.EMPTY,
                message: Messages.EMPTY,
                mainButtonText: MainButtonText.CLOSE,
                isSubmitting: false
            })
        } else {
            // send the email
            axios({
                method: "POST", 
                url: "/api/send_generic_email", 
                data: {
                    userEmail: this.props.userEmail,
                    message: message.replaceAll("\n", "<br/>\n")
                }
            }).then((response) => {
                if (response.data.msg === 'success') {
                    this.setState({
                        heading: Headings.EMAIL_SENT,
                        message: Messages.EMAIL_SENT,
                        mainButtonText: MainButtonText.CLOSE,
                        isSubmitting: false
                    })
                } else if (response.data.msg === 'fail') {
                    this.setState({
                        heading: Headings.ERROR,
                        message: Messages.ERROR,
                        mainButtonText: MainButtonText.CLOSE,
                        isSubmitting: false
                    })
                }
            })
        }
    }

    cancelButtonClicked() {
        this.resetModal()
        this.props.setShowGenericEmailModal(false)
    }

    render() {
        let showGenericEmailModal = this.props.showGenericEmailModal && (this.props.isPoolManager || this.props.isSecondaryManager)

        if (!showGenericEmailModal)
            return <></>
        else {
            const isDark = (this.props.theme === Themes.DARK)

            return (
                <div id="email_report_modal" style={showGenericEmailModal ? {display:"block"} : {display:"none"}}>
                    { this.state.isSubmitting ? 
                        <img id="modal_loading_spinner" src={isDark ? "/loading_dark.gif" : "/loading_light.gif"} alt="loading"/> 
                        :
                        <div id="email_report_modal_content">
                            <h2 className="submit-status-heading">{this.state.heading}</h2>
                            <p>{this.state.message}</p><br/>
  
                            <form id="email_report_message" onSubmit={this.mainButtonClicked}>
                                {this.state.heading === Headings.DEFAULT && <label><textarea className="modal_text_input" placeholder="Message" id="report_message" rows="8"></textarea></label>}
                                <br/>
                                <div id="btn_modal_container">
                                    {this.state.heading === Headings.DEFAULT && <button className="btn_plain btn_modal manager_tool_btn_modal" id="btn_cancel_modal" type="button" onClick={() => this.cancelButtonClicked()}>Cancel</button>}
                                    <button className="btn_accent btn_modal manager_tool_btn_modal" id="btn_submit_modal" type="submit">{this.state.mainButtonText}</button>
                                </div>
                            </form>

                        </div>
                    }
                </div>
            )
        }
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        userEmail: state.userEmail,
        showGenericEmailModal: state.showGenericEmailModal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShowGenericEmailModal: (response) => dispatch({ type: SET_SHOW_GENERIC_EMAIL_MODAL, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GenericEmailModal)
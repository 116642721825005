import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'

import {
    SET_CURRENT_ENTRY,
    TOGGLE_PICK_BUTTON_VISIBILITY,
    PickButtonVisibilities,
    CLEAR_PENDING_PICKS
} from '../../actions/actions'

import '../../css/entrybar.css'

class EntryBar extends Component {

    // TODO: add icons indicating Player Status on the actual tab for more clarity

    switchTab(userEntry) {
        this.props.setCurrentEntry(userEntry)
        this.props.clearPendingPicks() // clear pending picks just in case

        if (this.props.pickButtonVisibility === PickButtonVisibilities.BLOCK)
            this.props.togglePickButtonVisibility()
    }

    render() {
        return (
            <div id="infobar">
                {!isMobile && this.props.entries.length <= 4 && <h4 className="infobar_item">Entries:</h4>}
                {this.props.entries.map(e => 
                    <div key={`${e.sUserId}-${e.userEntry}`} className="infobar_item entry_tab" id={e.userEntry === this.props.currentEntry ? "active_tab" : ""} onClick={() => this.switchTab(e.userEntry)}>{e.entryName}</div>    
                )}
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        entries: state.entries,
        currentEntry: state.currentEntry,
        pickButtonVisibility: state.pickButtonVisibility,
        maxEntriesPerUser: state.maxEntriesPerUser
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setCurrentEntry: (entry) => dispatch({ type: SET_CURRENT_ENTRY, entry }),
        togglePickButtonVisibility: () => dispatch({ type: TOGGLE_PICK_BUTTON_VISIBILITY }),
        clearPendingPicks: () => dispatch({ type: CLEAR_PENDING_PICKS })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EntryBar)
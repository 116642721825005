import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../css/avatar.css'
import { isMobile } from 'react-device-detect'

import {
    SET_SHOW_AVATAR_MENU,
}
from '../../actions/settings'

class Avatar extends Component {
    constructor (props) {
        super(props)
        this.state = {
            hover: false,
            avatarSize: isMobile ? 24 : 32,
            doShow: false,
        }
    }

    handleMouseIn() {
        if (this.props.showAvatarMenu === false) {
            this.setState({ hover: true })
        }
    }
      
    handleMouseOut() {
        if (this.props.showAvatarMenu === false) {
            this.setState({ hover: false })
        }
    }

    handleClick() {
        const newVal = !this.state.doShow
        this.setState({ hover: false, doShow: newVal })
        this.props.handleAvatarClick(newVal)
    }

    render() {
        return (
            <div className="avatar">
                <div onClick={() => this.handleClick()} onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)}>
                    <img className="avatarImg" width={this.state.avatarSize} height={this.state.avatarSize} alt={this.props.alt} src={this.props.src} />
                </div>
                <div className="avatarTooltip" style={{display: this.state.hover ? 'block' : 'none'}}>{this.props.alt}</div>
           </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        showAvatarMenu: state.showAvatarMenu,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShowAvatarMenu: (response) => dispatch({ type: SET_SHOW_AVATAR_MENU, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Avatar)
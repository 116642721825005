import React, { Component } from 'react'
import { connect } from 'react-redux'

import axios from 'axios'
import '../../css/avatar.css'

import {
    updateUserThemeSetting,
    Themes,
    SET_SUSER_ID,
    SET_USER_NAME,
    SET_USER_EMAIL,
    SET_USER_IMG,
    SET_DO_LOGOUT,
    SET_SHOW_AVATAR_MENU,
}
from '../../actions/settings'

class AvatarMenu extends Component {
    constructor (props) {
        super(props)
        this.handleSignOut = this.handleSignOut.bind(this)
        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }

    // if outside the menu is clicked hide it
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target))
            this.props.setShowAvatarMenu(false)
    }

    renderThemeButton() {
        return <img id="btn_theme" type="image" alt="toggle theme"
                    width="26" height="26"
                    src={`/images/${this.props.theme === Themes.DARK ? "light" : "dark"}modeicon.png`}
            />
    }

    // renderEmailButton() {
    //     return <img id="btn_theme" type="image" alt="toggle theme"
    //                 width="26" height="26"
    //                 src={`/images/${this.props.theme === Themes.DARK ? "dark" : "light"}mode_signout.png`}
    //         />
    // }

    renderSignOutButton() {
        return <img id="btn_theme" type="image" alt="toggle theme"
                    width="26" height="26"
                    src={`/images/${this.props.theme === Themes.DARK ? "dark" : "light"}mode_signout.png`}
            />
    }

    handleUpdateEmail() {
        return
    }

    handleSignOut() {
        axios.get("/auth/logout")
        .then((response) => {
            if (response.data.success === true) {
                this.props.setDoLogout(true)
                this.props.setUserId(null)
                this.props.setUserName(null)
                this.props.setUserEmail(null)
                this.props.setUserImg(null)
            }
        })
        .catch(() => { console.log("Error on logout") })
    }

    render() {
        return this.props.showAvatarMenu && <div ref={this.setWrapperRef} className="avatarMenu">
            <div className="avatarMenuHeader">
                <p className="avatarMenuHeading"><strong>{this.props.userName}</strong></p>
                <table className="avatarMenuSubheading">
                    <tbody>
                        {/* <tr>
                            <td>Login with:</td>
                            <td>{this.props.userEmail}</td>
                        </tr> */}
                        <tr>
                            <td>Send emails to:</td>
                            <td>{this.props.userEmail}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="avatarMenuItem" onClick={() => this.props.updateTheme()}>
                <div className="avatarMenuItemText">Switch to {this.props.theme === Themes.DARK ? "Light" : "Dark"} theme </div>
                <div className="avatarMenuItemIcon">{this.renderThemeButton()}</div>
            </div>
            {/* <div className="avatarMenuItem" onClick={this.handleUpdateEmail}>
                <div className="avatarMenuItemText">Update email address</div>
                <div className="avatarMenuItemIcon">{this.renderEmailButton()}</div>
            </div> */}
            <div className="avatarMenuItem" onClick={this.handleSignOut}>
                <div className="avatarMenuItemText">Sign out</div>
                <div className="avatarMenuItemIcon">{this.renderSignOutButton()}</div>
            </div>
        </div>
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        theme: state.theme,
        userName: state.userName,
        userEmail: state.userEmail,
        showAvatarMenu: state.showAvatarMenu,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateTheme: () => dispatch(updateUserThemeSetting()),
        setUserId: (id) => dispatch({ type: SET_SUSER_ID, id }),
        setUserName: (response) => dispatch({ type: SET_USER_NAME, response }),
        setUserEmail: (response) => dispatch({ type: SET_USER_EMAIL, response }),
        setUserImg: (response) => dispatch({ type: SET_USER_IMG, response }),
        setDoLogout: (response) => dispatch({ type: SET_DO_LOGOUT, response }),
        setShowAvatarMenu: (response) => dispatch({ type: SET_SHOW_AVATAR_MENU, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvatarMenu)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect'
import Avatar from '../General/Avatar'
import AvatarMenu from '../General/AvatarMenu'
import { SET_PAGE, Themes, Pages, SET_SHOW_HAM_MENU, SET_SHOW_AVATAR_MENU } from '../../actions/settings'

import '../../css/header.css'

class Header extends Component {
    handleHamClick() {
        this.props.setShowHamMenu(!this.props.showHamMenu)
    }

    handleAvatarClick(setTo) {
        this.props.setShowAvatarMenu(setTo)
    }

    renderHamburgerIcon() {
        return (
            <input className={"header_item" + (this.props.showHamMenu ? " hamburger_icon_active" : "")} id="hamburger_icon" type="image" alt="hamburger menu icon"
                    width="30" height="30"
                    src={this.props.theme === Themes.DARK ? "/images/ham_dark.png" : "/images/ham_light.png"}
                    onClick={() => this.handleHamClick()}
            />
        )
    }

    // use the Pages obj values to map a nav item for each page
    renderNavBarItems() {
        return Object.values(Pages).map((p, index) => <div key={index} className="header_item header_nav" onClick={() => this.props.setPage(p)}>{p}</div>)
    }
    
    render() {
        return (
            <div id="header">
                {isMobile && this.renderHamburgerIcon()}
                <h1 className="header_item" id="header_title">Survivin' {!isMobile && <span className="header_subheading">Pool Manager</span>}</h1>
                {!isMobile && this.renderNavBarItems()}
                <div id="header_info">
                    <div className="header_item" id="header_currweek"><strong>{this.props.currentSeason.includes("POST") ? "Postseason " : ""}Week {this.props.currentWeek}</strong></div>
                    <div className="header_item" >
                        <Avatar
                            alt={this.props.userName}
                            src={this.props.userImg}
                            handleAvatarClick={(setTo) => this.handleAvatarClick(setTo)}
                        />
                        <AvatarMenu />
                    </div>
                </div>
            </div>
        )
    }
}

// ------- Redux -------

// takes a piece of state, which is part of your store, and passes it into your component as a prop.
// in this ex., you can now refer to teamsPicked as a prop
// we do this to avoid passing in the ENTIRE state - just a small subset
function mapStateToProps(state) {
    return {
        currentWeek: state.currentWeek,
        theme: state.theme,
        currentSeason: state.currentSeason,
        showHamMenu: state.showHamMenu,
        showAvatarMenu: state.showAvatarMenu,
        userName: state.userName,
        userImg: state.userImg,
    }
}

// pass in a prop called setPickButtonVisibility that calls the action creator function
const mapDispatchToProps = dispatch => {
    return {
        setPage: (response) => dispatch({ type: SET_PAGE, response }),
        setShowHamMenu: (response) => dispatch({ type: SET_SHOW_HAM_MENU, response }),
        setShowAvatarMenu: (response) => dispatch({ type: SET_SHOW_AVATAR_MENU, response }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
  
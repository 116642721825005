import { combineReducers } from 'redux'
import {
    TOGGLE_PICK_BUTTON_VISIBILITY,
    SET_PICK_BUTTON_VISIBILITY,
    ADD_PICKS,
    UPDATE_TEAM_RESULT,
    UPDATE_PICK,
    SET_CURRENT_WEEK,
    ADD_ENTRY,
    UPDATE_STATUS,
    UPDATE_BUYBACK_CHOICE,
    SET_CURRENT_ENTRY,
    ADD_PENDING_PICK,
    REMOVE_PENDING_PICK,
    CLEAR_PENDING_PICKS,
    SET_POOL_ID,
    SET_ENTRY_FEE,
    SET_BUYBACK_ALLOWED,
    SET_BUYBACK_FEE,
    SET_MANAGER_EMAIL,
    SET_SIGNUP_DEADLINE,
    CLEAR_ENTRY_DATA,
    CLEAR_PICK_DATA,
    SET_ADMIN_EMAIL,
    PickButtonVisibilities,
    LOAD_POOL_ENTRY,
    UPDATE_POOL_ENTRY_PAID,
    UPDATE_POOL_ENTRY_BB_PAID,
    ADD_POOL_PICK,
    UPDATE_POOL_PICK_TEAMS,
    CLEAR_POOL_PICK_DATA,
    CLEAR_POOL_ENTRY_DATA,
    SET_TEAM_RESULTS,
    SET_POOL_WINNERS
} from '../actions/actions'
import {
    SET_SUSER_ID,
    SET_CURRENT_SEASON,
    TOGGLE_THEME,
    SET_THEME,
    SET_PAGE,
    SET_TEAMS_PER_WEEK,
    Themes,
    Pages,
    SET_USER_NAME,
    SET_USER_EMAIL,
    SET_USER_IMG,
    SET_SHOW_BUYBACK_MODAL,
    SET_IS_OFFSEASON,
    SET_RESUBMIT_ALLOWED,
    SET_MAX_ENTRIES_PER_USER,
    SET_YEAR,
    SET_NUM_REGULAR_SEASON_WEEKS,
    SET_NUM_POSTSEASON_WEEKS,
    SET_SHOW_HAM_MENU,
    SET_SHOW_MANUAL_PICK_MODAL,
    SET_SHOW_EMAIL_REPORT_MODAL,
    SET_SHOW_GENERIC_EMAIL_MODAL,
    SET_PAYMENT_STRING,
    SET_TIMEZONE_OFFSET,
    SET_DO_LOGOUT,
    SET_SHOW_AVATAR_MENU,
} from '../actions/settings'

//import * as reducers from './reducers' // if you want to have each reducer in a separate file in the future, you can import all of them like this
//// and then down below, do: const rootReducer = combineReducers(reducers)

//const { ALIVE } = PlayerStatuses
const { NONE, BLOCK } = PickButtonVisibilities
const { LIGHT, DARK } = Themes
const { MY_PICKS } = Pages

function pickButtonVisibility(state = NONE, action) {
    switch (action.type) {
        case TOGGLE_PICK_BUTTON_VISIBILITY:
            return (state === NONE ? BLOCK : NONE)
        case SET_PICK_BUTTON_VISIBILITY:
            return action.response
        default:
            return state
    }
}

function currentWeek(state = 0, action) {
    switch (action.type) {
        case SET_CURRENT_WEEK:
            return action.week
        default:
            return state
    }
}

function currentEntry(state = "0", action) {
    switch (action.type) {
        case SET_CURRENT_ENTRY:
            return action.entry
        default:
            return state
    }
}

function entries(state = [], action) {
    switch (action.type) {
        case ADD_ENTRY:
            return [
                ...state,
                {
                    entryId: action.entry.entryId,
                    userEntry: action.entry.userEntry,
                    entryName: action.entry.entryName,
                    userName: action.entry.userName,
                    status: action.entry.status,
                    buybackChoice: action.entry.buybackChoice
                }
            ]
        case UPDATE_STATUS:
            return state.map((entries) => {
                if (entries.userEntry === action.entry.userEntry) {
                    return Object.assign({}, entries, {
                        status: action.entry.status
                    })
                }
                return entries
            })
        case UPDATE_BUYBACK_CHOICE:
            return state.map((entries) => {
                if (entries.userEntry === action.entry.userEntry) {
                    return Object.assign({}, entries, {
                        buybackChoice: action.entry.buybackChoice
                    })
                }
                return entries
            })
        case CLEAR_ENTRY_DATA:
            return []
        default:
            return state
    }
}

function picks(state = [], action) {
    switch (action.type) {
        case ADD_PICKS: // adds a new pick to picks
            return [
                ...state,
                {
                    sUserId: action.response.sUserId,
                    pickId: action.response.pickId,
                    userEntry: action.response.userEntry,
                    season: action.response.season,
                    week: action.response.week,
                    teams: action.response.teams,
                    results: action.response.results,
                    teamGameTimes: action.response.teamGameTimes,
                    aggregatedResult: action.response.aggregatedResult
                }
            ]
        case UPDATE_TEAM_RESULT: // updates an existing element given week # and the new result
            return state.map((pick) => {
                if (pick.week === action.response.week && pick.userEntry === action.response.userEntry) {
                    return Object.assign({}, pick, {
                        result: action.response.result
                    })
                }
                return pick
            })
        case UPDATE_PICK: // updates pick with new team for matching pickId
            return state.map((pick) => {
                if (pick.week === action.response.week && pick.userEntry === action.response.userEntry) {
                    return Object.assign({}, pick, {
                        teams: action.response.teams,
                        teamGameTimes: action.response.teamGameTimes,
                    })
                }
                return pick
            })
        case CLEAR_PICK_DATA:
            return []
        default:
            return state
    }
}

function pendingPick(state = [], action) {
    switch (action.type) {
        case ADD_PENDING_PICK:
            return [
                ...state,
                { teamSelected: action.pick.teamSelected, gameTimeUTC: action.pick.gameTimeUTC }
            ]
        case REMOVE_PENDING_PICK:
            return state.filter(p => p !== action.pick.teamSelected)
        case CLEAR_PENDING_PICKS:
            return []
        default:
            return state
    }
}

function poolId(state = "", action) {
    switch (action.type) {
        case SET_POOL_ID:
            return action.response
        default:
            return state
    }
}

function entryFee(state = null, action) {
    switch (action.type) {
        case SET_ENTRY_FEE:
            return action.response
        default:
            return state
    }
}

function buybackAllowed(state = true, action) {
    switch (action.type) {
        case SET_BUYBACK_ALLOWED:
            return action.response
        default:
            return state
    }
}

function buybackFee(state = null, action) {
    switch (action.type) {
        case SET_BUYBACK_FEE:
            return action.response
        default:
            return state
    }
}

function managerEmail(state = "", action) {
    switch (action.type) {
        case SET_MANAGER_EMAIL:
            return action.response
        default:
            return state
    }
}

function adminEmail(state = "", action) {
    switch (action.type) {
        case SET_ADMIN_EMAIL:
            return action.response
        default:
            return state
    }
}

function signupDeadline(state = "", action) {
    switch (action.type) {
        case SET_SIGNUP_DEADLINE:
            return action.response
        default:
            return state
    }
}

function poolPicks(state = [], action) {
    switch (action.type) {
        case ADD_POOL_PICK: // adds a new pool pick to poolPicks
            return [
                ...state,
                {
                    pickId: action.response.pickId,
                    sUserId: action.response.sUserId,
                    userEntry: action.response.userEntry,
                    season: action.response.season,
                    week: action.response.week,
                    teams: action.response.teams,
                    results: action.response.results,
                    aggregatedResult: action.response.aggregatedResult,
                    isAutoAssigned: action.response.isAutoAssigned,
                }
            ]
        case UPDATE_POOL_PICK_TEAMS: // updates teams for an existing pool pick
            return state.map((p) => {
                if (p.sUserId === action.response.sUserId &&
                    p.userEntry === action.response.userEntry &&
                    p.week === action.response.week &&
                    p.season === action.response.season) {
                    return Object.assign({}, p, {
                        teams: action.response.teams
                    })
                }
                return p
            })
        case CLEAR_POOL_PICK_DATA:
            return []
        default:
            return state
    }
}

function poolEntries(state = [], action) {
    switch (action.type) {
        case LOAD_POOL_ENTRY:
            return [
                ...state,
                {
                    sUserId: action.response.sUserId,
                    userEntry: action.response.userEntry,
                    entryName: action.response.entryName,
                    userName: action.response.userName,
                    status: action.response.status,
                    buybackChoice: action.response.buybackChoice,
                    paidEntry: action.response.paidEntry,
                    paidBuyback: action.response.paidBuyback,
                    weekEliminated: action.response.weekEliminated
                }
            ]
        case UPDATE_POOL_ENTRY_PAID:
            return state.map((e) => {
                if (e.sUserId === action.response.sUserId && e.userEntry === action.response.userEntry) {
                    return Object.assign({}, e, {
                        paidEntry: action.response.paidEntry
                    })
                }
                return e
            })
        case UPDATE_POOL_ENTRY_BB_PAID:
            return state.map((e) => {
                if (e.sUserId === action.response.sUserId && e.userEntry === action.response.userEntry) {
                    return Object.assign({}, e, {
                        paidBuyback: action.response.paidBuyback
                    })
                }
                return e
            })
        case CLEAR_POOL_ENTRY_DATA:
            return []
        default:
            return state
    }
}

// ----------------- Settings -------------------
function doLogout(state = false, action) {
    switch (action.type) {
        case SET_DO_LOGOUT:
            return action.response
        default:
            return state
    }
}

function sUserId(state = null, action) {
    switch (action.type) {
        case SET_SUSER_ID:
            return action.id
        default:
            return state
    }
}

function currentSeason(state = "", action) {
    switch (action.type) {
        case SET_CURRENT_SEASON:
            return action.response
        default:
            return state
    }
}

function year(state = "", action) {
    switch (action.type) {
        case SET_YEAR:
            return action.response
        default:
            return state
    }
}

function isOffseason(state = false, action) {
    switch (action.type) {
        case SET_IS_OFFSEASON:
            return action.response
        default:
            return state
    }
}

function userName(state = null, action) {
    switch (action.type) {
        case SET_USER_NAME:
            return action.response
        default:
            return state
    }
}

function userEmail(state = null, action) {
    switch (action.type) {
        case SET_USER_EMAIL:
            return action.response
        default:
            return state
    }
}

function userImg(state = null, action) {
    switch (action.type) {
        case SET_USER_IMG:
            return action.response
        default:
            return state
    }
}

function theme(state = LIGHT, action) {
    switch (action.type) {
        case TOGGLE_THEME:
            return (state === DARK ? LIGHT : DARK)
        case SET_THEME:
            return action.theme
        default:
            return state
    }
}

function page(state = MY_PICKS, action) {
    switch (action.type) {
        case SET_PAGE:
            return action.response
        default:
            return state
    }
}

function teamsPerWeek(state = 1, action) {
    switch (action.type) {
        case SET_TEAMS_PER_WEEK:
            return action.response
        default:
            return state
    }
}

function showBuybackModal(state = false, action) {
    switch (action.type) {
        case SET_SHOW_BUYBACK_MODAL:
            return action.response
        default:
            return state
    }
}

function resubmitAllowed(state = false, action) {
    switch (action.type) {
        case SET_RESUBMIT_ALLOWED:
            return action.response
        default:
            return state
    }
}

function maxEntriesPerUser(state = 0, action) {
    switch (action.type) {
        case SET_MAX_ENTRIES_PER_USER:
            return action.response
        default:
            return state
    }
}

function numRegularSeasonWeeks(state = 0, action) {
    switch (action.type) {
        case SET_NUM_REGULAR_SEASON_WEEKS:
            return action.response
        default:
            return state
    }
}

function numPostseasonWeeks(state = 0, action) {
    switch (action.type) {
        case SET_NUM_POSTSEASON_WEEKS:
            return action.response
        default:
            return state
    }
}

function showHamMenu(state = false, action) {
    switch (action.type) {
        case SET_SHOW_HAM_MENU:
            return action.response
        default:
            return state
    }
}

function showManualPickModal(state = false, action) {
    switch (action.type) {
        case SET_SHOW_MANUAL_PICK_MODAL:
            return action.response
        default:
            return state
    }
}

function showEmailReportModal(state = false, action) {
    switch (action.type) {
        case SET_SHOW_EMAIL_REPORT_MODAL:
            return action.response
        default:
            return state
    }
}

function showGenericEmailModal(state = false, action) {
    switch (action.type) {
        case SET_SHOW_GENERIC_EMAIL_MODAL:
            return action.response
        default:
            return state
    }
}

function paymentString(state = "", action) {
    switch (action.type) {
        case SET_PAYMENT_STRING:
            return action.response
        default:
            return state
    }
}

function teamResults(state = [], action) {
    switch (action.type) {
        case SET_TEAM_RESULTS:
            return action.response
        default:
            return state
    }
}

function poolWinners(state = [], action) {
    switch (action.type) {
        case SET_POOL_WINNERS:
            return action.response
        default:
            return state
    }
}

function timezoneOffset(state = 0, action) {
    switch (action.type) {
        case SET_TIMEZONE_OFFSET:
            return action.response
        default:
            return state
    }
}

function showAvatarMenu(state = false, action) {
    switch (action.type) {
        case SET_SHOW_AVATAR_MENU:
            return action.response
        default:
            return state
    }
}

const rootReducer = combineReducers({
    pickButtonVisibility,
    currentWeek,
    entries,
    currentEntry,
    picks,
    pendingPick,
    sUserId,
    currentSeason,
    year,
    userName,
    userEmail,
    userImg,
    theme,
    page,
    teamsPerWeek,
    poolId,
    entryFee,
    buybackAllowed,
    buybackFee,
    managerEmail,
    adminEmail,
    signupDeadline,
    showBuybackModal,
    isOffseason,
    resubmitAllowed,
    maxEntriesPerUser,
    numRegularSeasonWeeks,
    numPostseasonWeeks,
    showHamMenu,
    showManualPickModal,
    poolPicks,
    poolEntries,
    showEmailReportModal,
    showGenericEmailModal,
    paymentString,
    teamResults,
    timezoneOffset,
    poolWinners,
    doLogout,
    showAvatarMenu,
})
  
export default rootReducer

import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../css/infopage.css'

class InfoPage extends Component {
    // TODO: Venmo string should be supplied from DB, not hardcoded
    render() {
        return ( 
            <div id="info_container">
                <div id="info_content">
                    <h2 className="info_heading">Pool Information</h2>
                    <br/>
                    <h3 className="info_heading">Send Payments To</h3>
                    <span className="address">Mike Scaringe</span><br/>
                    <span className="address">{this.props.paymentString}</span><br/>
                    <br/>
                    <span className="address">Venmo: @Mike-Scaringe-2</span>
                    <br/><br/><br/>
                    <h3 className="info_heading">Report an Issue/Get Help</h3>
                    <p>For any Survivin' web app-related issues, contact Camden: <a href={`mailto:${this.props.adminEmail}`}>{this.props.adminEmail}</a></p>
                    <br/>
                    <h3 className="info_heading">Survivor Pool Rules</h3>
                    <p>Each entry is ${this.props.entryFee}, maximum {this.props.maxEntriesPerUser} entries per person.</p>
                    <p>Pick 1 team per week to win (no point spreads, just win the game). If you win, your entry survives to the next week.</p>
                    <p>You are not allowed to repeat teams (Survivin' will not allow you to submit a team that has been picked in prior weeks).</p>
                    <p>If you suffer your first loss, you have two options:</p>
                    <ol>
                        <li>You may buy back in for an additional ${this.props.buybackFee} fee and continue playing. You may only buy back in after your first loss - if you lose a second time, you are eliminated.</li>
                        <li>You can choose not to buy back in, in which case you are eliminated.</li>
                    </ol>
                    <p>If the pool reaches Week 13 without a winner, you will need to pick 2 teams per entry instead of 1 starting in Week 13. Both teams must win in order to survive.</p>
                    <p>Ties count as a loss. In order to survive, the team(s) you select must win the game.</p>  
                    <p>All picks must be in by Sunday at 1:00 PM EST. If picking a game that starts earlier in the NFL week (such as Thursday Night Football), it must be submitted at the top of the hour of the game's start time (so if the game starts at 8:15 PM, it must be submitted by 8:00 PM).</p>
                    <p>You may change your team(s) picked as many times as you like as long as it's before the deadline. If picking 2 teams per week and one team is playing/has played, you may still change the other team as long as it's before the deadline for that game.</p>
                    <p>If no pick has been submitted for an entry by Sunday at 1:00 PM EST, the entry will automatically be assigned the home team of the last game of the week (Monday Night Football). If this team has already been taken, then the away team will be assigned. If both have been taken, it will traverse the list of games backwards (home team, then away) until a non-repeat team is found.</p>
                    <p>The pool ends when there is 1 entry remaining, and that player will win the total pot. If all remaining entries are eliminated in a given week, then each of those entries will advance to the next week so that a winner can be decided.</p>
                    <p>If a small number of entries remain, those players may decide to split the pot and end the pool if all parties agree.</p>
                    <p>If all {this.props.numRegularSeasonWeeks} weeks of the regular season have passed and there is still no winner, the pool will continue into the playoffs:</p>
                    <ul>
                        <li>Any team in the playoffs can be picked (regardless of whether you picked them in the regular season), but you cannot repeat teams in the playoffs.</li>
                        <li>Pick one team to win each week. If you win, you survive; if you lose, you are eliminated. If you haven't used your optional buyback in the regular season, you may use it if you suffer a loss in the playoffs.</li>
                        <li>If you reach a round of the playoffs and don't have any teams left to select (because you already took them in earlier playoff rounds), you are eliminated.</li>
                        <li>The pool ends when there is 1 entry remaining, and that player will win the total pot. If all remaining entries are eliminated in a given week, then each of those entries will advance to the next week so that a winner can be decided.</li>
                        <li>If no single winner has been decided after the Super Bowl, the pot will be split evenly amongst the remaining players.</li>
                    </ul>
                    
                    <br/>
                    <h3 className="info_heading">Late Picks</h3>
                    <p>If a pick is not in by 1:00 EST on Sunday, the system will auto-assign the pick. There are absolutely no exceptions for late picks. The Survivin' system will not allow you to submit a pick after the deadline - it does not matter if the email has not been sent out yet.</p>
                    <p>Picks can be submitted starting on Tuesday and updated as many times as desired before the deadline. "Technical difficulties" will not be accepted as a valid reason for not submitting, because if needed, a pick can always be emailed and added to the system manually by an admin (as long as the email was sent before the 1:00 PM EST deadline).</p>

                    <br/>
                    <h3 className="info_heading">Web Hosting Fees</h3>
                    <p>The web hosting platform used to host the Survivin' website costs $5 per month. Once the pool has a winner, this hosting fee will be deducted from the final payout. The amount deducted will depend on how many months it takes for there to be a winner. In other words, the hosting fee will be $5 multiplied by the number of months it takes to run the pool this year starting with August.</p>

                    <br/>
                    <h3 className="info_heading">Developer Note</h3>
                    <p>I have made and will continue to make every effort to ensure that the Survivin' web app is always available, always accurate, and always enforces the rules of the pool outlined on this page. That said, I am a one-man software development team and occassionally mistakes happen.
                       If the Survivin' Pool Manager web app allows a user to perform an action that is contradictory to the rules outlined above, the pool manager reserves the right to make a manual correction so that the rules of the pool can be accurately and fairly enforced.   
                    </p>

                    <br/>
                </div>
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        adminEmail: state.adminEmail,
        entryFee: state.entryFee,
        buybackFee: state.buybackFee,
        maxEntriesPerUser: state.maxEntriesPerUser,
        numRegularSeasonWeeks: state.numRegularSeasonWeeks,
        paymentString: state.paymentString
    }
}

export default connect(mapStateToProps, null)(InfoPage)
import React, { Component } from 'react'
import { connect } from 'react-redux'

import axios from 'axios'
import _ from 'lodash'

import { sanitize } from '../../utils'
import '../../css/firsttimemodal.css'
import { PlayerStatuses, BuybackChoices } from '../../actions/actions'
// import {
// 	RegExpMatcher,
// 	englishDataset,
// 	englishRecommendedTransformers,
// } from 'obscenity'

const ErrorMessages = {
    DUPLICATE: "There is already an entry in your pool with this name.",
    BAD_WORD: "This entry name is not allowed.", // TODO: not implemented yet
    EMPTY: "A blank entry name is not allowed.",
}

class CreateEntriesModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            numEntriesToCreate: 1,
            isSetupComplete: false,
            isLoading: true,
            firstNameLastInitial: this.props.userName.substr(0, this.props.userName.indexOf(' ') + 2),
            entryNames: new Set(),
            poolEntryNames: [],
            errorMessage: null,
            errorMessageIdx: -1,
            isCreateDisabled: false,
            badWordMatcher: null,
            agreePrivacy: false,
            agreeRules: false,
        }
    }

    componentDidMount() {
        const initEntryNames = []
        for (var i = 0; i < this.state.numEntriesToCreate; i++) {
            initEntryNames[i] = (this.state.numEntriesToCreate === 1 ? this.state.firstNameLastInitial : this.state.firstNameLastInitial + ` ${i + 1}`)
        }
        this.setState({ entryNames: initEntryNames })

        axios.get("/api/all_entries", { params: { year: this.props.year } })
            .then((response) => {
                this.setState({ poolEntryNames: response.data.map(e => e.entryName), isLoading: false })
            })
            .catch(() => { console.log("Api data entries - Internal server error") })

        // const badWordMatcher = new RegExpMatcher({
        //     ...englishDataset.build(),
        //     ...englishRecommendedTransformers,
        // })
        // this.setState({ badWordMatcher: badWordMatcher })
    }

    decrement() {
        const newEntryNames = _.cloneDeep(this.state.entryNames)
        newEntryNames.pop() // remove last element
        if (newEntryNames.length === 1) {
            const newEntryNamesNoIndex = newEntryNames.map((x) => { return x.substring(0, x.length - 2) })
            this.setState({ numEntriesToCreate: this.state.numEntriesToCreate - 1, entryNames: newEntryNamesNoIndex })
        } else {
            this.setState({ numEntriesToCreate: this.state.numEntriesToCreate - 1, entryNames: newEntryNames })
        }
        this.checkIfErrors(newEntryNames)
    }

    increment() {
        const newEntryNames = _.cloneDeep(this.state.entryNames)
        newEntryNames.push(`${this.state.firstNameLastInitial} ${this.state.numEntriesToCreate + 1}`) // add new element
        // if going from 1 to 2, then need to add the index onto the first element string
        if (newEntryNames.length === 2) {
            const newEntryNamesWithIndex = newEntryNames.map((x, idx) => {
                return idx === 0 && !x.endsWith(` ${idx + 1}`)
                    ? `${x} ${idx + 1}`
                    : x
            })
            this.setState({ numEntriesToCreate: this.state.numEntriesToCreate + 1, entryNames: newEntryNamesWithIndex })
        } else {
            this.setState({ numEntriesToCreate: this.state.numEntriesToCreate + 1, entryNames: newEntryNames })
        }
        this.checkIfErrors(newEntryNames)
    }

    // check if any duplicates or if any empty
    checkIfAnyInvalidNames(testEntryNames) {
        let matchIndex = -1
        let errorMsg = null
        testEntryNames.forEach((name, idx) => {
            if (name.trim() === "") {
                matchIndex = idx
                errorMsg = ErrorMessages.EMPTY
            } else if (this.state.poolEntryNames.includes(name)) {
                matchIndex = idx
                errorMsg = ErrorMessages.DUPLICATE
            }
        })
        return [matchIndex, errorMsg]
    }

    checkIfErrors(newEntryNames) {
        const [matchIndex, errorMsg] = this.checkIfAnyInvalidNames(newEntryNames)
        this.setState({ errorMessage: errorMsg, errorMessageIdx: matchIndex, isCreateDisabled: (matchIndex !== -1)})
    }

    updateAgreePrivacy() {
        this.setState({ agreePrivacy: !this.state.agreePrivacy })
    }

    updateAgreeRules() {
        this.setState({ agreeRules: !this.state.agreeRules })
    }

    updateEntryName(e, idx) {
        e.persist()
        const newEntryNames = _.cloneDeep(this.state.entryNames)
        const newVal = sanitize(e.target.value)
        newEntryNames[idx] = newVal 

        this.checkIfErrors(newEntryNames)
        this.setState({ entryNames: newEntryNames})

        // const hasBadWords = newVal && this.state.badWordMatcher ? this.state.badWordMatcher.hasMatch(newVal) : false
        // if (hasBadWords) {
        //     this.setState({ errorMessage: ErrorMessages.BAD_WORD, errorMessageIdx: idx })
        //     return
        // }
    }

    submitEntries() {
        return new Promise((resolve, reject) => {
            for (var i = 0; i < this.state.numEntriesToCreate; i++) {
                axios({
                    method: "POST",
                    url: "/api/create_new_entry",
                    data: {
                        sUserId: this.props.sUserId,
                        season: this.props.currentSeason,
                        userEntry: String(i),
                        entryName: this.state.entryNames[i],
                        status: PlayerStatuses.ALIVE.toUpperCase(),
                        buybackChoice: BuybackChoices.NONE,
                        paidEntry: false,
                        paidBuyback: false,
                        weekEliminated: 0
                    }
                })
                    .then(() => {
                        if (i === this.state.numEntriesToCreate) {
                            resolve("Entries created")
                        }
                    })
                    .catch(() => { reject('New user: Internal server error') })
            }
        })
    }

    createEntries() {
        const promiseEntries = this.submitEntries(this.state.firstNameLastInitial)
        Promise.all([promiseEntries]).then(() => {
            this.setState({ isSetupComplete: true })
            this.props.onSetupCompletion()
        })
    }

    isSignupDeadlinePassed() {
        let currentDateTime = new Date()
        let signupDeadline = new Date(this.props.signupDeadline)
        return (currentDateTime > signupDeadline)
    }

    render() {
        if (this.state.isSetupComplete && !this.state.isLoading)
            return <></>
        else {
            return (
                <div id="first_time_modal">
                    <div id="first_time_modal_content">
                        <h2>How Many Entries?</h2><br />
                        <strong>Use the + and - buttons to select your number of entries.</strong><br /><br />
                        <button className="entry_count_button" disabled={this.state.numEntriesToCreate <= 1} onClick={() => this.decrement()}>-</button>
                        <span id="entry_count">{this.state.numEntriesToCreate}</span>
                        <button className="entry_count_button" disabled={this.state.numEntriesToCreate >= this.props.maxEntriesPerUser} onClick={() => this.increment()}>+</button>
                        <br /><br />
                        
                        {[...Array(this.state.numEntriesToCreate).keys()].map((i) => <div key={`entry-field-${i}`}>
                            {this.state.errorMessage && i === this.state.errorMessageIdx && <p className="entry_error">{this.state.errorMessage}</p>}
                            <p>
                                <label><strong>Entry {i + 1} name: </strong><input className="input_field" defaultValue={this.state.entryNames[i]} onInput={(e) => this.updateEntryName(e, i)} /></label>
                            </p>
                        </div>
                        )}
                        <br /><br />
                        <p>The entry fee for your pool is <strong>${(this.props.entryFee).toFixed(2)}</strong>.
                        This pool allows one optional buyback per entry for an additional <strong>${(this.props.buybackFee).toFixed(2)}</strong>.
                        Your pool manager will provide information regarding payment.</p>
                        <br/>
                        <h2>By signing up:</h2>
                        <p><input type="checkbox" key={`agreePrivacy`}
                            onChange={() => this.updateAgreePrivacy()}
                            checked={this.state.agreePrivacy}
                            value={"agreePrivacy"}
                        /><span className="entry_error"> *</span> I agree to the Survivin' <a href='privacyPolicy.html' target="_blank">Privacy Policy</a></p>
                        <p><input type="checkbox" key={`agreeRules`}
                            onChange={() => this.updateAgreeRules()}
                            checked={this.state.agreeRules}
                            value={"agreeRules"}
                        /><span className="entry_error"> *</span> I agree to the rules of the pool as outlined <a href='poolRules.html' target="_blank">on this rules page</a>. I understand that late picks will not be accepted under any circumstances.</p>
                        <div id="btn_modal_container">
                            <button className="btn_accent btn_modal" id="btn_first_time_modal" disabled={this.state.isCreateDisabled || !this.state.agreePrivacy || !this.state.agreeRules} onClick={() => this.createEntries()}>Create {this.state.numEntriesToCreate} Entr{this.state.numEntriesToCreate === 1 ? "y" : "ies"}</button>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        sUserId: state.sUserId,
        entryFee: state.entryFee,
        buybackFee: state.buybackFee,
        userName: state.userName,
        signupDeadline: state.signupDeadline,
        currentSeason: state.currentSeason,
        maxEntriesPerUser: state.maxEntriesPerUser,
        year: state.year,
    }
}

export default connect(mapStateToProps, null)(CreateEntriesModal)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PlayerStatuses, Results } from '../../actions/actions'
import { Themes, SET_SHOW_EMAIL_REPORT_MODAL } from '../../actions/settings'

import axios from 'axios'
import _ from 'lodash'

import { sanitize } from '../../utils'
import '../../css/emailreportmodal.css'

const Headings = {
    DEFAULT: "Send Email: Picks",
    RESULTS: "Send Email: Results",
    REPORT_SENT: "Picks Report Sent",
    ERROR: "Error"
}

const Messages = {
    DEFAULT: "This will send an email report to all members of the pool showing which teams each person picked this week, and the result of the game if there is one.",
    REPORT_SENT: "The email report has been successfully sent to all members of the pool.",
    ERROR: "The report could not be sent. Please try again later."
}

const MainButtonText = {
    SEND_REPORT: "Send Email",
    CLOSE: "Close"
}

class EmailReportModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            heading: Headings.DEFAULT,
            message: Messages.DEFAULT,
            mainButtonText: MainButtonText.SEND_REPORT,
            weekToReport: this.props.currentWeek
        }
    }

    // if it's Tuesday, it means the week has incremented - so if we're sending report is should be the previous week
    // TODO: timezone - may want to rehandle this
    componentDidMount() {
        let date = new Date()
        if (date.getDay() === 2 && this.props.currentWeek > 1) {
            this.setState({ weekToReport: this.props.currentWeek - 1 })
        }
    }

    mainButtonClicked = event => {
        event.preventDefault()
        if (this.state.mainButtonText === MainButtonText.SEND_REPORT) {
            this.setState({ isSubmitting: true })
            this.sendEmailReport(sanitize(event.target.elements.report_message.value))
        } else if (this.state.mainButtonText === MainButtonText.CLOSE) {
            this.resetModal()
            this.props.setShowEmailReportModal(false)
        }
    }

    resetModal() {
        this.setState({
            isSubmitting: false,
            heading: Headings.DEFAULT,
            message: Messages.DEFAULT,
            mainButtonText: MainButtonText.SEND_REPORT
        })
    }

    sendEmailReport(message) {
        const thisWeeksPicks = this.props.poolPicks.filter(p => p.season === this.props.currentSeason && p.week === this.state.weekToReport)

        // filter to entries that are either eliminated this week, or active
        const entriesToDisplay = this.props.poolEntries.filter(e => (e.status === PlayerStatuses.ELIMINATED.toUpperCase() && e.weekEliminated === parseInt(this.state.weekToReport) + (this.props.currentSeason.includes("POST") ? this.props.numRegularSeasonWeeks : 0)) || (e.status !== PlayerStatuses.ELIMINATED.toUpperCase()))

        const emailTableRows = entriesToDisplay.map(e => {
            let entryTeam = thisWeeksPicks.filter(p => p.sUserId === e.sUserId && p.userEntry === e.userEntry)
            e["teams"] = entryTeam.length > 0 ? entryTeam[0].teams : ""
            e["result"] = entryTeam.length > 0 ? entryTeam[0].aggregatedResult : ""
            return e
        })

        // send the email
        axios({
            method: "POST", 
            url: "/api/send_email_report", 
            data: {
                userEmail: this.props.userEmail,
                entries: _.sortBy(emailTableRows, ['entryName']),
                week: this.state.weekToReport,
                optionalMessage: message.replaceAll("\n", "<br/>\n")
            }
        }).then((response) => {
            if (response.data.msg === 'success') {
                this.setState({
                    heading: Headings.REPORT_SENT,
                    message: Messages.REPORT_SENT,
                    mainButtonText: MainButtonText.CLOSE,
                    isSubmitting: false
                })
            } else if (response.data.msg === 'fail') {
                this.setState({
                    heading: Headings.ERROR,
                    message: Messages.ERROR,
                    mainButtonText: MainButtonText.CLOSE,
                    isSubmitting: false
                })
            }
        })
    }

    cancelButtonClicked() {
        this.resetModal()
        this.props.setShowEmailReportModal(false)
    }

    renderMissingPicksMessage(numEntriesWithPicks, numExpectedPicks) {
        if (numEntriesWithPicks === 0)
            return <p><img className="email-report-icon" alt="warning icon" src="/images/yellow_warning_icon.png" width="20px" height="20px"/>No picks have been submitted yet this week.</p> // the other message takes care of this
        else if (numEntriesWithPicks === numExpectedPicks)
            return <p><img className="email-report-icon" alt="green check" src="/images/greencheck.png" width="20px" height="20px"/>All picks submitted for this week.</p>
        else
            return <p><img className="email-report-icon" alt="warning icon" src="/images/yellow_warning_icon.png" width="20px" height="20px"/>
                {`${numExpectedPicks - numEntriesWithPicks} ${numExpectedPicks - numEntriesWithPicks === 1 ? "entry is" : "entires are"} still missing a pick. (${numEntriesWithPicks}/${numExpectedPicks} submitted)`}
            </p>
    }

    renderNumPendingPicksMessage(numEntriesWithPicks, numPendingPicks, numExpectedPicks) {
        if (numEntriesWithPicks === 0)
            return <></>
        else if (numEntriesWithPicks === numExpectedPicks && numPendingPicks === 0)
            return <p><img className="email-report-icon" alt="green check" src="/images/greencheck.png" width="20px" height="20px"/>All picks have a result (Win/Loss).</p>
        else if (numPendingPicks === numExpectedPicks)
            return <></> // no need to show message if no picks have results
        else
            return <p><img className="email-report-icon" alt="warning icon" src="/images/yellow_warning_icon.png" width="20px" height="20px"/>
                {`${numPendingPicks} pick${numPendingPicks === 1 ? "" : "s"} awaiting a result. (${numEntriesWithPicks - numPendingPicks}/${numExpectedPicks} submitted picks have a result)`}
            </p>
    }

    formatPicksString(numEntriesWithPicks, numPendingPicks) {
        if (numEntriesWithPicks === 0 || numPendingPicks !== 0)
            return ""

        const incorrectEntries = this.props.poolPicks.filter(p => p.season === this.props.currentSeason && p.week === this.state.weekToReport)
        .filter(p => p.aggregatedResult === "LOSS")

        if (incorrectEntries.length === 0)
            return "Everyone survived this week."
        
        const incorrectPicks = incorrectEntries.map(p => {return p.teams}).flat()

        const occurrences = incorrectPicks.reduce((obj, item) => {
            obj[item] = (obj[item] || 0) + 1
            return obj
        }, {})

        const arrStrings = Object.entries(occurrences).map(([keyTeam, valueFreq]) => {
            return `${keyTeam} x${valueFreq}`
        })

        return `${incorrectEntries.length} entries suffered losses this week (${arrStrings.map(x => x)})`
    }

    render() {
        let showEmailReportModal = this.props.showEmailReportModal && (this.props.isPoolManager || this.props.isSecondaryManager)

        if (!showEmailReportModal)
            return <></>
        else {
            const isDark = (this.props.theme === Themes.DARK)

            const numExpectedPicks = this.props.poolEntries.filter(e => (e.status === PlayerStatuses.ELIMINATED.toUpperCase() && e.weekEliminated === parseInt(this.state.weekToReport) + (this.props.currentSeason.includes("POST") ? this.props.numRegularSeasonWeeks : 0)) || e.status !== PlayerStatuses.ELIMINATED.toUpperCase()).length
            const numEntriesWithPicks = this.props.poolPicks.filter(p => p.season === this.props.currentSeason && p.week === this.state.weekToReport).length
            const numPendingPicks = this.props.poolPicks.filter(p => p.season === this.props.currentSeason && p.week === this.state.weekToReport && p.aggregatedResult === Results.PENDING).length

            const picksString = this.formatPicksString(numEntriesWithPicks, numPendingPicks)

            return (
                <div id="email_report_modal" style={showEmailReportModal ? {display:"block"} : {display:"none"}}>
                    { this.state.isSubmitting ? 
                        <img id="modal_loading_spinner" src={isDark ? "/loading_dark.gif" : "/loading_light.gif"} alt="loading"/> 
                        :
                        <div id="email_report_modal_content">
                            <h2 className="submit-status-heading">{numPendingPicks === 0 ? Headings.RESULTS : this.state.heading}{this.state.heading !== Headings.ERROR ? ` Week ${this.state.weekToReport}` : ""}</h2>
                            <p>{this.state.message}</p><br/>
                            
                            {(this.state.heading === Headings.DEFAULT || this.state.heading === Headings.RESULTS) && <>
                                {this.renderMissingPicksMessage(numEntriesWithPicks, numExpectedPicks)}<br/>
                                {this.renderNumPendingPicksMessage(numEntriesWithPicks, numPendingPicks, numExpectedPicks)}<br/><br/>
                            </>}
                            
                            <form id="email_report_message" onSubmit={this.mainButtonClicked}>
                                {(this.state.heading === Headings.DEFAULT || this.state.heading === Headings.RESULTS) && <>
                                    <p>Add an optional message to the email:</p>
                                    <label><textarea className="modal_text_input" placeholder="Optional message" id="report_message" rows="4">{picksString}</textarea></label>
                                </>
                                }
                                <br/>
                                <div id="btn_modal_container">
                                    {(this.state.heading === Headings.DEFAULT || this.state.heading === Headings.RESULTS) && <button className="btn_plain btn_modal manager_tool_btn_modal" id="btn_cancel_modal" type="button" onClick={() => this.cancelButtonClicked()}>Cancel</button>}
                                    <button className="btn_accent btn_modal manager_tool_btn_modal" id="btn_submit_modal" type="submit">{this.state.mainButtonText}</button>
                                </div>
                            </form>

                        </div>
                    }
                </div>
            )
        }
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        userEmail: state.userEmail,
        currentWeek: state.currentWeek,
        currentSeason: state.currentSeason,
        showEmailReportModal: state.showEmailReportModal,
        poolPicks: state.poolPicks,
        poolEntries: state.poolEntries,
        numRegularSeasonWeeks: state.numRegularSeasonWeeks
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShowEmailReportModal: (response) => dispatch({ type: SET_SHOW_EMAIL_REPORT_MODAL, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailReportModal)
import React, { Component } from 'react'
import { connect } from 'react-redux'

import axios from 'axios'
import _ from 'lodash'

import { Themes } from '../../actions/settings'
import { ADD_POOL_PICK, LOAD_POOL_ENTRY, CLEAR_POOL_PICK_DATA, CLEAR_POOL_ENTRY_DATA } from '../../actions/actions'

import '../../css/poolview.css'
import PoolViewTable from './PoolViewTable'

class PoolViewContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isReady: false,
            isPoolManager: false,
            isSecondaryManager: false,
            scoresByWeek: null,
            hardDeadline: null,
            isBeforeHardDeadline: true,
        }
    }

    componentDidMount() {
        const promisePickData = this.loadPickData()
        const promiseEntryData = this.loadEntryData()

        const promiseIsPoolManager = axios.get("/api/is_user_pool_manager")
            .then((response) => { this.setState({ isPoolManager: response.data.isPoolManager }) })
            .catch(() => { console.log("Pool manager check - Internal server error") })

        const promiseIsSecondaryManager = axios.get("/api/is_user_secondary_manager")
            .then((response) => { this.setState({ isSecondaryManager: response.data.isSecondaryManager }) })
            .catch(() => { console.log("Pool manager check - Internal server error") })

        // get the dynamic data from the DB
        const promiseScoreData = axios.get("/api/api_data")
            .then((response) => {
                this.setState({
                    scoresByWeek: response.data[0].scoresByWeek,
                    hardDeadline: new Date(response.data[0].hardDeadline),
                    isBeforeHardDeadline: new Date() < new Date(response.data[0].hardDeadline),
                })
            })
            .catch(() => { console.log("Api data - Internal server error") })

        Promise.all([promisePickData, promiseEntryData, promiseIsPoolManager, promiseIsSecondaryManager, promiseScoreData]).then(() => {
            this.setState({ isReady: true })
        })
    }

    loadPickData() {
        this.props.clearPoolPickData()
        return (
            axios.get("/api/get_all_picks_pool_view", { params: { year: this.props.year, isOffseason: this.props.isOffseason } })
                .then((response) => {
                    _.orderBy(response.data, ['season', 'userEntry', 'week'], ['desc', 'asc', 'asc'])
                        .map(p => this.props.addPoolPick({
                            sUserId: p.sUserId,
                            season: p.season,
                            pickId: p.pickId,
                            userEntry: p.userEntry,
                            week: p.week,
                            teams: p.teams,
                            results: p.results,
                            teamGameTimes: p.teamGameTimes,
                            aggregatedResult: p.aggregatedResult,
                            isAutoAssigned: p.isAutoAssigned,
                        }))
                })
                .catch(() => { console.log("Api data picks - Internal server error") })
        )
    }

    loadEntryData() {
        this.props.clearPoolEntryData()
        return (
            axios.get("/api/all_entries", { params: { year: this.props.year } })
                .then((response) => {
                    response.data.map(e => this.props.loadPoolEntry({
                        sUserId: e.sUserId,
                        userEntry: e.userEntry,
                        entryName: e.entryName,
                        userName: e.userName,
                        status: e.status,
                        buybackChoice: e.buybackChoice,
                        paidEntry: e.paidEntry,
                        paidBuyback: e.paidBuyback,
                        weekEliminated: e.weekEliminated
                    }))
                })
                .catch(() => { console.log("Api data entries - Internal server error") })
        )
    }

    render() {
        const isDark = (this.props.theme === Themes.DARK)
        if (!this.state.isReady) { // show loading spinner while data loads
            return (
                <div id="loading" className={isDark ? "darkTheme" : "lightTheme"}>
                    <img id="loading_spinner" src={isDark ? "/loading_dark.gif" : "/loading_light.gif"} alt="loading" />
                </div>
            )
        } else {
            return <PoolViewTable
                isPoolManager={this.state.isPoolManager}
                isSecondaryManager={this.state.isSecondaryManager}
                scoresByWeek={this.state.scoresByWeek}
                hardDeadline={this.state.hardDeadline}
                isBeforeHardDeadline={this.state.isBeforeHardDeadline}
            />
        }
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        year: state.year,
        theme: state.theme,
        userEmail: state.userEmail,
        isOffseason: state.isOffseason
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadPoolEntry: (response) => dispatch({ type: LOAD_POOL_ENTRY, response }),
        addPoolPick: (response) => dispatch({ type: ADD_POOL_PICK, response }),
        clearPoolEntryData: () => dispatch({ type: CLEAR_POOL_ENTRY_DATA }),
        clearPoolPickData: () => dispatch({ type: CLEAR_POOL_PICK_DATA })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PoolViewContainer)
import { Results } from './actions/actions'

// replaces special chars with their character entity reference, and trims
export function sanitize(string) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
        "`": '&grave;'
    }
    const reg = /[&<>"'/]/ig
    return string.replace(reg, (match) => (map[match])).trim()
}

// given an array of picks, filter it only to the currentEntry number. season is an optional argument
export function filterCurrentEntryPicks(picks, currentEntry, season = null) {
    return picks.filter(p => p.userEntry === currentEntry && (season ? p.season === season : true))
}

// given an array of picks, filter it only to the currentEntry and week. season is an optional argument
export function filterCurrentEntryPicksThisWeek(picks, currentEntry, week, season = null) {
    return picks.filter(p => p.userEntry === currentEntry && p.week === week && (season ? p.season === season : true))
}

// given an array of picks, filter it only to pending picks for the current week. season is an optional argument
export function filterCurrentEntryPicksPendingThisWeek(picks, currentEntry, week, season = null) {
    return picks.filter(p => p.userEntry === currentEntry && p.week === week && p.aggregatedResult === Results.PENDING && (season ? p.season === season : true))
}

import axios from 'axios'

// action types
export const TOGGLE_PICK_BUTTON_VISIBILITY = 'TOGGLE_PICK_BUTTON_VISIBILITY'
export const SET_PICK_BUTTON_VISIBILITY = 'SET_PICK_BUTTON_VISIBILITY'
export const ADD_PICKS = 'ADD_PICKS'
export const UPDATE_PICK = 'UPDATE_PICK'
export const UPDATE_TEAM_RESULT = 'UPDATE_TEAM_RESULT'
export const SET_CURRENT_WEEK = 'SET_CURRENT_WEEK'
export const ADD_ENTRY = 'ADD_ENTRY'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_BUYBACK_CHOICE = 'UPDATE_BUYBACK_CHOICE'
export const SET_CURRENT_ENTRY = 'SET_CURRENT_ENTRY'
export const ADD_PENDING_PICK = 'ADD_PENDING_PICK'
export const REMOVE_PENDING_PICK = 'REMOVE_PENDING_PICK'
export const CLEAR_PENDING_PICKS = 'CLEAR_PENDING_PICKS'
export const SET_POOL_ID = 'SET_POOL_ID'
export const SET_ENTRY_FEE = 'SET_ENTRY_FEE'
export const SET_BUYBACK_ALLOWED = 'SET_BUYBACK_ALLOWED'
export const SET_BUYBACK_FEE = 'SET_BUYBACK_FEE'
export const SET_MANAGER_EMAIL = 'SET_MANAGER_EMAIL'
export const SET_ADMIN_EMAIL = 'SET_ADMIN_EMAIL'
export const SET_SIGNUP_DEADLINE = 'SET_SIGNUP_DEADLINE'
export const CLEAR_ENTRY_DATA = 'CLEAR_ENTRY_DATA'
export const CLEAR_PICK_DATA = 'CLEAR_PICK_DATA'
export const LOAD_POOL_ENTRY = 'LOAD_POOL_ENTRY'
export const UPDATE_POOL_ENTRY_PAID = 'UPDATE_POOL_ENTRY_PAID'
export const UPDATE_POOL_ENTRY_BB_PAID = 'UPDATE_POOL_ENTRY_BB_PAID'
export const ADD_POOL_PICK = 'ADD_POOL_PICK'
export const UPDATE_POOL_PICK_TEAMS = 'UPDATE_POOL_PICK_TEAMS'
export const CLEAR_POOL_ENTRY_DATA = 'CLEAR_POOL_ENTRY_DATA'
export const CLEAR_POOL_PICK_DATA = 'CLEAR_POOL_PICK_DATA'
export const SET_TEAM_RESULTS = 'SET_TEAM_RESULTS'
export const SET_POOL_WINNERS = 'SET_POOL_WINNERS'

// other constants
export const PlayerStatuses = {
    ALIVE: 'Alive',
    ALIVE_BUYBACK: 'Alive (Buyback)',
    ELIMINATED: 'Eliminated'
}

export const BuybackChoices = {
    NONE: 'NONE', // not applicable, user is alive
    PENDING: 'PENDING', // user has a loss but has not decided
    IGNORE: 'IGNORE', // user was presented the modal but chose "I'll decide later"
    YES: 'YES', // user has decided to use buyback
    NO: 'NO' // user has decided not to use buyback
}

export const PickButtonVisibilities = {
    BLOCK: 'BLOCK',
    NONE: 'NONE'
}

export const Results = {
    WIN: 'WIN',
    LOSS: 'LOSS',
    PENDING: 'PENDING'
}

/*
 * Action creators, which each return an action (an object)
 *
 * type: a string that describes what the change is
 * payload: any extra info needed to perform the action
 */

// thunk: add the team picked to state and also to DB
export const submitPick = ( pick ) => {
    return dispatch => {
        return axios({
            method: "POST",
            url: "/api/save_pick",
            data: {
                sUserId: pick.sUserId,
                season: pick.season,
                pickId: pick.pickId,
                userEntry: pick.userEntry,
                week: pick.week,
                teams: pick.teams,
                results: pick.results,
                teamGameTimes: pick.teamGameTimes,
                aggregatedResult: pick.aggregatedResult,
                submitTimestamp: new Date().toString(),
                updateTimestamp: pick.updateTimestamp || null,
                resultTimestamp: null,
                isSubmittedMobile: pick.isSubmittedMobile,
                isAutoAssigned: pick.isAutoAssigned
            }
        })
        .then((res) => {
            console.log('Pick has been sent to server')
            dispatch(addPick(pick))
            return res.data
        })
        .catch((res) => {
            console.log('Pick: Internal server error')
            return res.data
        })
    }
}

// thunk: update a pick's teams in redux and DB
export const updatePick = ( pick ) => {
    return dispatch => { 
        return axios({ 
            method: "PUT",
            url: `/api/update_pick_team/${pick.userEntry}/${pick.week}/${pick.season}`,
            data: { sUserId: pick.sUserId, teams: pick.teams, teamGameTimes: pick.teamGameTimes }
        })
        .then((response) => {
            console.log('Update pick has been sent to server')
            dispatch(updateExistingPick(pick))
            return response.data
        })
        .catch((response) => {
            console.log('Update pick: Internal server error')
            return response.data
        })
    }
}

export function togglePickButtonVisibility() {
    return { type: TOGGLE_PICK_BUTTON_VISIBILITY }
}

export function setPickButtonVisibility(response) {
    return { type: SET_PICK_BUTTON_VISIBILITY, response }
}

export function addPick(response) {
    return { type: ADD_PICKS, response }
}

export function updateExistingPick(response) {
    return { type: UPDATE_PICK, response }
}

export function updateTeamResult(response) {
    return { type: UPDATE_TEAM_RESULT, response }
}

export function setCurrentWeek(week) {
    return { type: SET_CURRENT_WEEK, week }
}

export function addEntry(entry) {
    return { type: ADD_ENTRY, entry }
}

export function updateStatus(entry) {
    return { type: UPDATE_STATUS, entry }
}

export function updateBuybackChoice(entry) {
    return { type: UPDATE_BUYBACK_CHOICE, entry }
}

// thunk: update buyback choice
export const updateBuybackChoiceThunk = ( response ) => {
    return dispatch => {
        return axios({
            method: "PUT",
            url: `/api/update_buyback_choice/${response.userEntry}/${response.year}`,
            data: {
                sUserId: response.sUserId,
                buybackChoice: response.buybackChoice,
            }
        })
        .then((res) => {
            console.log('Buyback choice saved successfully')
            dispatch(updateBuybackChoice(response))
            return res.data
        })
        .catch((res) => {
            console.log('Buyback choice: Internal server error')
            return res.data
        })
    }
}

// thunk: update entry status
export const updateEntryStatusThunk = ( response ) => {
    return dispatch => {
        return axios({
            method: "PUT",
            url: `/api/update_entry_status/${response.userEntry}/${response.year}`,
            data: {
                sUserId: response.sUserId,
                status: response.status,
                weekEliminated: response.weekEliminated,
            }
        })
        .then((res) => {
            console.log('Entry status saved successfully')
            dispatch(updateStatus(response))
            return res.data
        })
        .catch((res) => {
            console.log('Update status: Internal server error')
            return res.data
        })
    }
}

// thunk: manual add for pool view pick
export const addManualPoolViewPick = ( response ) => {
    return dispatch => {        
        return axios({
            method: "POST",
            url: "/api/save_pick_manual",
            data: {
                sUserId: response.sUserId,
                season: response.season,
                pickId: response.pickId,
                userEntry: response.userEntry,
                week: response.week,
                teams: response.teams,
                results: response.results,
                teamGameTimes: response.teamGameTimes,
                aggregatedResult: response.aggregatedResult,
                submitTimestamp: new Date().toString(),
                updateTimestamp: response.updateTimestamp || null,
                resultTimestamp: null,
                isSubmittedMobile: response.isSubmittedMobile,
                isAutoAssigned: response.isAutoAssigned,
            }
        })
        .then((res) => {
            console.log('Pick has been sent to server')
            dispatch(addPoolPick(response))
            return res.data
        })
        .catch((res) => {
            console.log('Pick: Internal server error')
            return res.data
        })
    }
}

// thunk: manual update for pool view pick
export const updateManualPoolViewPick = ( response ) => {
    return dispatch => {      
        return axios({ 
            method: "PUT",
            url: `/api/manual_update_pick_team/${response.sUserId}/${response.userEntry}/${response.week}/${response.season}`,
            data: { teams: response.teams, teamGameTimes: response.teamGameTimes }
        })
        .then((res) => {
            console.log('Update pick has been sent to server')
            dispatch(updatePoolPickTeams(response))
            return res.data
        })
        .catch((res) => {
            console.log('Update pick: Internal server error')
            return res.data
        })
    }
}

// thunk: update entry paid value both in pool view and DB
export const updateEntryPaid = ( response ) => {
    return dispatch => {   
        return axios({
            method: "PUT",
            url: `/api/update_entry_paid/${response.sUserId}/${response.userEntry}/${response.year}`,
            data: { paidEntry: response.paidEntry }
        })
        .then((res) => {
            console.log('Update entry paid has been sent to server')
            dispatch(updatePoolEntryPaid(response))
            return res.data
        })
        .catch((res) => {
            console.log('Update entry paid: Internal server error')
            return res.data
        })
    }
}

// thunk: update buyback paid value both in pool view and DB
export const updateBuybackPaid = ( response ) => {
    return dispatch => {
       
        return axios({
            method: "PUT",
            url: `/api/update_buyback_paid/${response.sUserId}/${response.userEntry}/${response.year}`,
            data: { paidBuyback: response.paidBuyback }
        })
        .then((res) => {
            console.log('Update buyback paid has been sent to server')
            dispatch(updatePoolEntryBuybackPaid(response))   
            return res.data
        })
        .catch((res) => {
            console.log('Update buyback paid: Internal server error')
            return res.data
        })
        
    }
}

export function setCurrentEntry(entry) {
    return { type: SET_CURRENT_ENTRY, entry }
}

export function addPendingPick(pick) {
    return { type: ADD_PENDING_PICK, pick }
}

export function removePendingPick(pick) {
    return { type: REMOVE_PENDING_PICK, pick }
}

export function clearPendingPicks() {
    return { type: CLEAR_PENDING_PICKS }
}

export function setPoolId(response) {
    return { type: SET_POOL_ID, response }
}

export function setEntryFee(response) {
    return { type: SET_ENTRY_FEE, response }
}

export function setBuybackAllowed(response) {
    return { type: SET_BUYBACK_ALLOWED, response }
}

export function setBuybackFee(response) {
    return { type: SET_BUYBACK_FEE, response }
}

export function setManagerEmail(response) {
    return { type: SET_MANAGER_EMAIL, response }
}

export function setAdminEmail(response) {
    return { type: SET_ADMIN_EMAIL, response }
}

export function setSignupDeadline(response) {
    return { type: SET_SIGNUP_DEADLINE, response }
}

export function clearEntryData() {
    return { type: CLEAR_ENTRY_DATA }
}

export function clearPickData() {
    return { type: CLEAR_PICK_DATA }
}

export function loadPoolEntry(response) {
    return { type: LOAD_POOL_ENTRY, response }
}

export function updatePoolEntryPaid(response) {
    return { type: UPDATE_POOL_ENTRY_PAID, response }
}

export function updatePoolEntryBuybackPaid(response) {
    return { type: UPDATE_POOL_ENTRY_BB_PAID, response }
}

export function addPoolPick(response) {
    return { type: ADD_POOL_PICK, response }
}

export function updatePoolPickTeams(response) {
    return { type: UPDATE_POOL_PICK_TEAMS, response }
}

export function clearPoolPickData(response) {
    return { type: CLEAR_POOL_PICK_DATA, response }
}

export function clearPoolEntryData(response) {
    return { type: CLEAR_POOL_ENTRY_DATA, response }
}

export function setTeamResults(response) {
    return { type: SET_TEAM_RESULTS, response }
}

export function setPoolWinners(response) {
    return { type: SET_POOL_WINNERS, response }
}
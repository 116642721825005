export const stadiumImages = [
    { "img": "/stadiums/nix.jpg", "name": "State Farm Stadium", "location": "Glendale, AZ" },
    { "img": "/stadiums/mercedes.jpg", "name": "Mercedes-Benz Stadium", "location": "Atlanta, GA" },
    { "img": "/stadiums/mtbank.jpg", "name": "M&T Bank Stadium", "location": "Baltimore, MD" },
    { "img": "/stadiums/new_era.jpg", "name": "Highmark Stadium", "location": "Orchard Park, NY" },
    { "img": "/stadiums/bank_of_america.jpg", "name": "Bank of America Stadium", "location": "Charlotte, NC" },
    { "img": "/stadiums/soldier.jpg", "name": "Soldier Field", "location": "Chicago, IL" },
    { "img": "/stadiums/paul.jpg", "name": "Paycor Stadium", "location": "Cincinnati, OH" },
    { "img": "/stadiums/firstenergy.jpg", "name": "Cleveland Browns Stadium", "location": "Cleveland, OH" },
    { "img": "/stadiums/att.jpg", "name": "AT&T Stadium", "location": "Arlington, TX" },
    { "img": "/stadiums/empower.jpg", "name": "Empower Field at Mile High", "location": "Denver, CO" },
    { "img": "/stadiums/ford.jpg", "name": "Ford Field", "location": "Detroit, MI" },
    { "img": "/stadiums/lambeau.jpg", "name": "Lambeau Field", "location": "Green Bay, WI" },
    { "img": "/stadiums/nrg.jpg", "name": "NRG Stadium", "location": "Houston, TX" },
    { "img": "/stadiums/lucas_oil.jpg", "name": "Lucas Oil Stadium", "location": "Indianapolis, IN" },
    { "img": "/stadiums/tiaa.jpg", "name": "EverBank Stadium", "location": "Jacksonville, FL" },
    { "img": "/stadiums/arrowhead.jpg", "name": "GEHA Field at Arrowhead", "location": "Kansas City, MO" },
    { "img": "/stadiums/sofi.jpg", "name": "SoFi Stadium", "location": "Inglewood, CA" },
    { "img": "/stadiums/hard_rock.jpg", "name": "Hard Rock Stadium", "location": "Miami Gardens, FL" },
    { "img": "/stadiums/us_bank.jpg", "name": "U.S. Bank Stadium", "location": "Minneapolis, MN" },
    { "img": "/stadiums/gillette.jpg", "name": "Gillette Stadium", "location": "Foxborough, MA" },
    { "img": "/stadiums/superdome.jpg", "name": "Caesars Superdome", "location": "New Orleans, LA" },
    { "img": "/stadiums/metlife.jpg", "name": "MetLife Stadium", "location": "East Rutherford, NJ" },
    { "img": "/stadiums/allegiant.jpg", "name": "Allegiant Stadium", "location": "Las Vegas, NV" },
    { "img": "/stadiums/lincoln.jpg", "name": "Lincoln Financial Field", "location": "Philadelphia, PA" },
    { "img": "/stadiums/heinz.jpg", "name": "Acrisure Stadium", "location": "Pittsburgh, PA" },
    { "img": "/stadiums/levis.jpg", "name": "Levi's Stadium", "location": "Santa Clara, CA" },
    { "img": "/stadiums/centurylink.jpg", "name": "Lumen Field", "location": "Seattle, WA" },
    { "img": "/stadiums/raymond_james.jpg", "name": "Raymond James Stadium", "location": "Tampa, FL" },
    { "img": "/stadiums/nissan.jpg", "name": "Nissan Stadium", "location": "Nashville, TN" },
    { "img": "/stadiums/fedex.jpg", "name": "Commanders Field", "location": "Landover, MD" }
]
import React, { Component } from 'react'
import { connect } from 'react-redux'

import { SET_PAGE, SET_SHOW_HAM_MENU, Pages } from '../../actions/settings'

import '../../css/hamburgermenu.css'

class HamburgerMenu extends Component {
    constructor(props) {
        super(props)

        this.setWrapperRef = this.setWrapperRef.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }
    
    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }
    
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    setWrapperRef(node) {
        this.wrapperRef = node
    }
    
    // if outside the menu is clicked hide it
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target))
            this.props.setShowHamMenu(false)
    }

    // if a page is clicked switch to that page
    handlePageClick(page) {
        this.props.setPage(page)
        this.props.setShowHamMenu(false)
    }
    
    render() {
        return (this.props.showHamMenu &&
            <div ref={this.setWrapperRef} id="hamburger_menu">
                <h1 id="hamburger_menu_title">Survivin'</h1>
                {Object.values(Pages).map(p => <div key={p} className="hamburger_menu_item" id={p === this.props.page ? "hamburger_menu_item_current" : ""} onClick={() => this.handlePageClick(p)}>{p}</div>)}
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        page: state.page,
        showHamMenu: state.showHamMenu
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPage: (response) => dispatch({ type: SET_PAGE, response }),
        setShowHamMenu: (response) => dispatch({ type: SET_SHOW_HAM_MENU, response })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu)
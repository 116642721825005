import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../css/poolwinner.css'

class PoolWinner extends Component { 
    render() {
        let entryNameString = this.props.poolWinners.map(x => x.entryName).toString()
        entryNameString = entryNameString.replace(",", ", ")

        return (
            <div id="pool_winner">
                <span className='game_message'>Pool Winner{this.props.poolWinners.length > 1 ? "s" : ""}</span><br/><br/>
                <div id="pool_winner_left"><img className="trophy_icon" alt="trophy" src="/trophy.png"/></div>
                <div id="pool_winner_right">
                    <span id="pool_winner_names"><strong>{entryNameString}</strong></span>
                </div>
            </div>
        )
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        poolWinners: state.poolWinners
    }
}

export default connect(mapStateToProps)(PoolWinner)
import React, { Component } from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'

import { 
    Results,
    PlayerStatuses,
    addManualPoolViewPick,
    updateManualPoolViewPick
} from '../../actions/actions'
import { Themes, SET_SHOW_MANUAL_PICK_MODAL } from '../../actions/settings'

import '../../css/manualpickmodal.css'
import { isMobile } from 'react-device-detect'

const NFL_TEAMS = [ "ARI", "ATL", "BAL", "BUF", "CAR", "CHI", "CIN", "CLE", "DAL", "DEN", 
    "DET", "GB", "HOU", "IND", "JAX", "KC", "LAC", "LAR", "LV", "MIA", "MIN", "NE", "NO", 
    "NYG", "NYJ", "PHI", "PIT", "SF", "SEA", "TB", "TEN", "WAS" ]

class ManualPickModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isSubmitting: false,
            currentPicks: [],
            validTeamSelections: [],
            setPicksTo: [],
            submitUserId: "",
            submitUserEntry: "",
        }
    }

    handleEntry = event => {
        // get the sUserId and userEntry of the entry in question
        const val = event.target.value
        let {sUserId, userEntry} = this.props.poolEntries.filter(e => e.entryName === val)[0]
 
        // get this week's pick for the entry
        let thisWeekPicks = this.props.poolPicks.filter(p => p.sUserId === sUserId && p.userEntry === userEntry && p.week === this.props.currentWeek && p.season === this.props.currentSeason)
        let thisWeekTeams = thisWeekPicks.length > 0 ? thisWeekPicks[0].teams : [] // use an empty array if no picks
        
        // get all picks for this entry. Don't include this week's picks in this list
        let thisEntryPicks = this.props.poolPicks.filter(p => p.sUserId === sUserId && p.userEntry === userEntry && p.season === this.props.currentSeason && p.week !== this.props.currentWeek)
        let thisEntryTeams = [].concat.apply([], thisEntryPicks.map(p => p.teams)) // this flattens all the teams arrays down into one array of all teams
        let validSelections = NFL_TEAMS.filter(t => !thisEntryTeams.includes(t))

        this.setState({ currentPicks: thisWeekTeams, validTeamSelections: validSelections, submitUserId: sUserId, submitUserEntry: userEntry })
    }

    // TODO: don't allow same team(s) selected in multiple dropdowns
    handleTeamSelect = event => {
        const val = event.target.value
        const index = parseInt(event.target.name.split("select_team_dropdown_")[1]) // get the index of the dropdown used to select the team

        let newArr = this.state.setPicksTo
        newArr[index] = val // update the array at the appropriate index
        this.setState({ setPicksTo: newArr })
    }

    renderPickDropdowns() {
        let jsx = []
        for (let i = 0; i < this.props.teamsPerWeek; i++) {
            jsx.push(
                <select key={`team_default_${i}`} name={`select_team_dropdown_${i}`} className="select_team_dropdown" defaultValue={`team_default_${i}`} onChange={this.handleTeamSelect}>
                    <option disabled value={`team_default_${i}`} key={`team_default_${i}`}>Select a team</option>
                    {this.state.validTeamSelections.map(t => <option value={t} key={`team_${t}_${i}`}>{t}</option>)}
                </select>
            )
        }
        return jsx
    }

    getTeamGameTimes() {
        return this.state.setPicksTo.map(team => {
            const teamGame = this.props.scoresByWeek.filter(game => [game.AwayTeam, game.HomeTeam].includes(team)) // for this team, get its game object
            console.log(teamGame[0].DateTimeUTC)
            return teamGame && teamGame[0] ? `${String(teamGame[0].DateTimeUTC)}Z` : null // add Z for UTC time
        })
    }

    submitButtonClicked() {
        if (this.state.currentPicks.length === 0) { // no pick submitted so add
            this.props.addManualPoolViewPick({ // thunk to add a pool view pick in DB and redux
                sUserId: this.state.submitUserId,
                season: this.props.currentSeason,
                pickId: `${this.props.currentSeason}-${this.state.submitUserId}-${this.state.submitUserEntry}-${this.props.currentWeek}`,
                userEntry: this.state.submitUserEntry,
                week: this.props.currentWeek,
                teams: this.state.setPicksTo,
                results: this.state.setPicksTo.map(x => Results.PENDING),
                teamGameTimes: this.getTeamGameTimes(),
                aggregatedResult: Results.PENDING,
                isSubmittedMobile: isMobile,
                isAutoAssigned: false
            })
        } else { // pick submitted so update
            this.props.updateManualPoolViewPick({ // thunk to update teams of a pool view pick in DB and redux
                sUserId: this.state.submitUserId,
                userEntry: this.state.submitUserEntry,
                week: this.props.currentWeek,
                season: this.props.currentSeason,
                teams: this.state.setPicksTo,
                teamGameTimes: this.getTeamGameTimes(),
            })
        }

        this.setState({ currentPicks: [], validTeamSelections: [], setPicksTo: [] })
        this.props.setShowManualPickModal(false)
    }

    cancelButtonClicked() {
        this.setState({ currentPicks: [], validTeamSelections: [], setPicksTo: [] })
        this.props.setShowManualPickModal(false)
    }

    render() {
        let showManualPickModal = this.props.showManualPickModal && (this.props.isPoolManager || this.props.isSecondaryManager)

        if (!showManualPickModal)
            return <></>
        else {
            const isDark = (this.props.theme === Themes.DARK)
            return (
                <div id="manual_pick_modal" style={showManualPickModal ? {display:"block"} : {display:"none"}}>
                    { this.state.isSubmitting ? 
                        <img id="modal_loading_spinner" src={isDark ? "/loading_dark.gif" : "/loading_light.gif"} alt="loading"/> 
                        :
                        <div id="manual_pick_modal_content">
                            <h2 className="submit-status-heading">Manual Pick Tool</h2>
                            <label>Entry: <select name="select_entry_dropdown" id="select_entry_dropdown" defaultValue={"entry_default"} onChange={this.handleEntry}>
                                    <option disabled value="entry_default" key="entry_default">Select an entry</option>
                                    {_.orderBy(this.props.poolEntries.filter(e => e.status !== PlayerStatuses.ELIMINATED.toUpperCase()), ['entryName']).map(e => <option value={e.entryId} key={e.entryName}>{e.entryName}</option>)}
                                </select>
                            </label>
                            <p>Current Week: {(this.props.currentSeason.includes("POST") ? "Postseason " : "") + this.props.currentWeek}</p>
                            <br/>
                            <p>Current Team Picked: {this.state.currentPicks.length === 0 ? "None" : this.state.currentPicks.toString()}</p>
                            <p>Update Team To: {this.renderPickDropdowns()}</p><br/>
                            <div id="btn_modal_container">
                                <button className="btn_plain btn_modal manager_tool_btn_modal" id="btn_cancel_modal" onClick={() => this.cancelButtonClicked()}>Cancel</button>
                                <button className="btn_accent btn_modal manager_tool_btn_modal" id="btn_submit_modal" disabled={this.state.setPicksTo.length === 0 || this.state.setPicksTo.filter(x => x !== "").length < this.props.teamsPerWeek} onClick={() => this.submitButtonClicked()}>Update Pick</button>
                            </div>
                        </div>
                    }
                </div>
            )
        }
    }
}

// ------- Redux -------
function mapStateToProps(state) {
    return {
        currentWeek: state.currentWeek,
        currentSeason: state.currentSeason,
        teamsPerWeek: state.teamsPerWeek,
        showManualPickModal: state.showManualPickModal,
        poolPicks: state.poolPicks,
        poolEntries: state.poolEntries
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setShowManualPickModal: (response) => dispatch({ type: SET_SHOW_MANUAL_PICK_MODAL, response }),
        addManualPoolViewPick: (response) => dispatch(addManualPoolViewPick(response)),
        updateManualPoolViewPick: (response) => dispatch(updateManualPoolViewPick(response)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManualPickModal)